import Avatar from "react-avatar";
import React from "react";

function ClientAvatar({client, size = 35, style = null}) {
    const imgSize = size + 'px';
    return (
        <>
            {!client?.photo_uri && (
                <Avatar name={client?.clientName} round={true} size={size} style={style} />
            )}

            {client?.photo_uri && (
                <img src={client.photo_uri} alt={"loading"} width={imgSize} style={{ borderRadius: '88px', marginBottom: "-10px"}} />
            )}
        </>
    )
}

export { ClientAvatar }