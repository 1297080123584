import AdminDashLayout from "../components/AdminDashLayout";
import {
  Button,
  Card,
  Grid,
  List,
  Form,
  Modal,
  Dropdown,
  Header,
  Icon,
} from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaCheckCircle, FaMapMarkerAlt } from "react-icons/fa";
import ChatWidget from "../components/ChatWidget";
import AuthService from "../auth";
import React, { useCallback, useEffect, useState } from "react";
import { fetchProject, uploadFiles } from "../apiCalls";
import ProjectAssignedPeople from "../components/ProjectAssignedPeople";
import ProjectActivities from "../components/ProjectActivities";
import ProjectMilestones from "../components/ProjectMilestones";
import ProjectImageCardWithModal from "../components/ProjectImageCardWithModal";
import ProjectSchematics from "../components/ProjectSchematics";
import ProjectInvoices from "../components/ProjectInvoices";
import ProjectJobReport from "../components/ProjectJobReport";
import UpdateJobReportModal from "../components/UpdateJobReportModal";
import { projectStatusOptions, formatToBritishFormat } from "../utils";
import sendApiRequest from "../api";
import { toast } from "react-toastify";
import CreateInvoiceModalComponent from "../components/CreateInvoiceModalComponent";
import { formatM } from "../utils";
import ProjectInvoiceLogsTable from "../components/ProjectInvoiceLogsTable";
import ProjectNotificationLogs from "../components/ProjectNotificationsLog";
import { FaDownload, FaPlay } from "react-icons/fa6";
import SignDisclaimerComponent from "../components/SignDisclaimerComponent";
import ViewSignedDisclaimerComponent from "../components/ViewSignedDisclaimerComponent";
import ViewJobReportLogsModal from "../components/ViewJobReportLogsModal";
import ViewProjectLandlordCertLogs from "../components/ViewProjectLandlordCertLogs";
import { Dropzone, FileMosaic } from "@files-ui/react";

function ViewProject() {
  const { id } = useParams();
  const user = AuthService.getCurrentUser();
  const user_role = user.role;

  const navigate = useNavigate();

  const [project, setProject] = useState({});
  const [client, setClient] = useState({});
  const [loading, setLoading] = useState(true);
  const [projectImages, setProjectImages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [openJobModal, setjobReportModalOpen] = useState(false);
  const [openViewJobReportModal, setviewjobReportModalOpen] = useState(false)
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  const [projectStatus, setProjectStatus] = useState(project.projectStatus);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [projectReportData, setProjectReportData] = useState("");
  const [openSignDisclaimerModel, setOpenSignDisclaimerModel] = useState(false)
  const [projectReportLogs, setProjectReportLogs] = useState({})
  const [projectLandlordCertLogs, setProjectLandlordCert] = useState({})

  const [openCreateInvoiceModal, setCreateInvoiceModal] = useState(false)

  const [uploadJobReportModalOpen, setUploadJobReportModalOpen] = useState(false)

  const [files, setFiles] = useState([]);

  const fetchAndUpdateMessages = useCallback(() => {
    fetchProject(id, localStorage.getItem("token")).then((res) => {
      setProject(res.data[0]);
      setClient(res.data[0].clientData);
      setProjectImages(res.data[0].projectImages);
      setMessages(res.data[0].projectChats);
      setProjectReportLogs(res.data[0]?.projectReportLogs?.[0]);
      setProjectLandlordCert(res.data[0]?.projectLandlordCertLogs?.[0])
      setLoading(false);
    });
  }, [id]);

  useEffect(() => {
    // Fetch and update messages initially
    fetchAndUpdateMessages();

    // Set up an interval to fetch and update messages every 5 seconds
    const intervalId = setInterval(fetchAndUpdateMessages, 10000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchAndUpdateMessages]);

  // Define a function to update the messages
  const updateMessages = (newMessages) => {
    setMessages(newMessages);
  };

  // Define a function to handle upload completion
  const handleUploadComplete = (updatedImages) => {
    setProjectImages(updatedImages);
  };

  const handleSchematicsUploadComplete = (updatedSchematics) => {
    fetchAndUpdateMessages();
  };

  const handleInvoiceUploadComplete = (update) => {
    fetchAndUpdateMessages();
  };

  const handleUpdateJobReport = () => {
    closeJobReportModal();
  };

  const handleUpdateJobReportLogs = () => {
    closeViewJobReportModal()
  }

  const handleUpdateLandlordCert = () => {
    closeLandlordCertModal();
  };

  const handleUpdateLandlordCertLogs = () => {
    closeViewLandlordCertModal()
  }

  const handleUpdateSignDisclaimer = () => {
    toast("Reload page to view signed disclaimer or wait 5 seconds :)")
    closeSignDisclaimerModel();
  };

  const handleCreateInvoiceModal = () => {
    closeCreateInvoiceModalOpen()
  }

  function openUpdateStatusModal() {
    setUpdateStatusModal(true);
  }

  function closeUpdateStatusModal() {
    setUpdateStatusModal(false);
  }

  function handleDeleteModalClose() {
    setDeleteModalOpen(false);
  }

  function handleDeleteModalOpen() {
    setDeleteModalOpen(true);
  }

  const handleFormSubmit = () => {
    // Handle the submitted form data here
    handleUpdateJobReport();
  };

  const signDisclaimerModalOpenBtn = () => {
    setOpenSignDisclaimerModel(true)
  }

  const closeSignDisclaimerModel = () => {
    setOpenSignDisclaimerModel(false)
  }

  async function handleStatusUpdate() {
    const newActivity = [
      {
        user: {
          id: user.id,
          name: user?.firstname,
        },
        title: "Project Status Updated By",
        status: {
          type: projectStatus,
        },
        created_at: new Date().toISOString(),
      },
    ];

    const projectActivities = [...project.projectActivities, ...newActivity];

    const reqData = {
      projectStatus: projectStatus,
      projectActivities: projectActivities,
    };

    await sendApiRequest("post", "/projects/edit/" + project.id, reqData);
    //
    toast("Project Status Updated");
    //
    closeUpdateStatusModal();
  }

  const JobReportModalOpenBtn = () => setjobReportModalOpen(true);
  const closeJobReportModal = () => setjobReportModalOpen(false);

  const uploadJobReportModalOpenBtn = () => setUploadJobReportModalOpen(true);
  const closeUploadJobReportModal = () => setUploadJobReportModalOpen(false);

  const ViewJobReportModalOpenBtn = () => setviewjobReportModalOpen(true);
  const closeViewJobReportModal = () => setviewjobReportModalOpen(false);

  const ViewLandlordCertModalOpenBtn = () => setViewLandlordCertModalOpen(true);
  const closeViewLandlordCertModal = () => setViewLandlordCertModalOpen(false);

  const [openLandlordCertModal, setOpenLandlordCertModal] = useState(false);
  const [openViewLandlordCertModal, setViewLandlordCertModalOpen] = useState(false);

  const CreateInvoiceModalOpenBtn = () => setCreateInvoiceModal(true);
  const closeCreateInvoiceModalOpen = () => setCreateInvoiceModal(false);

  const closeLandlordCertModal = () => setViewLandlordCertModalOpen(false)

  const isPdf = (file) => {
    if (file && typeof file === "string") {
      const fileName = file.split("/").pop();
      return fileName.endsWith(".pdf");
    }
    return false;
  };

  const updateFiles = (incomingFiles) => {
    // Remove existing files with the same name and size from incomingFiles to avoid duplicates
    const filteredFiles = incomingFiles.filter((incomingFile) => {
      return !files.some(
        (existingFile) =>
          existingFile.name === incomingFile.name &&
          existingFile.size === incomingFile.size
      );
    });

    // Set the new files (including the filtered incoming files)
    setFiles([...files, ...filteredFiles]);
  };

  const removeFile = (fileId) => {
    // Remove a file from the state
    const updatedFiles = files.filter((file) => file.id !== fileId);
    setFiles(updatedFiles);
  };

  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];

  const isImage = (file) => {
    if (file && typeof file === "string") {
      const extension = file.split(".").pop();
      return imageExtensions.includes(`.${extension}`);
    }
    return false;
  };

  const handleUpload = async () => {
    try {
      if (files.length > 0) {
        setLoading(true);
        // Upload files and set the photoUri
        const uploadData = await uploadFiles(files);

        const up = await sendApiRequest('post', '/projects/edit/' + id, {
          projectJobReports: {
            manual: true,
            pdf_link: uploadData.data[0].urls.path
          }
        })

        console.log(up)
        
        toast("Upload completed");

        // Clear the uploaded files
        setFiles([]);

        if (up) {
          // Close the modal
          closeUploadJobReportModal();
          setLoading(false);
        }
      } else {
        toast("Kindly choose a file");
      }
    } catch (error) {
      // Handle errors here, e.g., show an error message to the user
      console.error("Error uploading files:", error);
    }
  };

  return (
    <>
      <AdminDashLayout>
        <Form loading={loading ? true : false}>
          <div style={{ paddingTop: 30, paddingRight: 20 }}>
            <Grid>
              <Grid.Column floated="left" width={9}>
                <h1>{project.projectName}</h1>

                {project.projectStartTime !== null && (
                  <>
                    <br />
                    Scheduled Time: {project.projectStartTime} - {formatToBritishFormat(project.projectStartDate)}
                  </>
                )}
                <div style={{ paddingTop: 20, paddingBottom: 10 }}>
                  <ProjectAssignedPeople
                    peopleAssigned={project.assignedToUserData}
                    viewProject={true}
                  />
                </div>
                <div
                  style={{ paddingTop: 20, paddingBottom: 10, display: "flex" }}
                >
                  <span style={{ fontSize: 15, paddingRight: 25 }}>
                    Client: {client && client.clientName ? client.clientName : project.clientName}
                  </span>
                  <span style={{ fontSize: 15 }}>
                    <a
                      target="_blank"
                      href={`https://google.com/maps/search/${project.clientAddress}`}
                    >
                      <FaMapMarkerAlt /> Site Address: {project.clientAddress}
                    </a>
                  </span>
                </div>
              </Grid.Column>
              <Grid.Column floated="right" width={6}>
                {user_role === 1 && (
                  <>
                    <div style={{ marginRight: 1, float: "right" }}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <Link to={`/dashboard/projects/view/${project.id}/view-attendance-logs`}>
                            <Button
                              style={{ color: '#000' }}
                              color="yellow"
                              content="Attendance"
                              icon="eye"
                              labelPosition="right"
                            />
                          </Link>
                          <Link to={"/dashboard/projects/edit/" + id}>
                            <Button

                              color="primary"
                              content="Edit"
                              icon="pencil"
                              labelPosition="right"
                            />
                          </Link>

                          <Button
                            color={"purple"}
                            content="Status"
                            icon="info"
                            labelPosition="right"
                            onClick={openUpdateStatusModal}
                          />
                          <Modal
                            open={updateStatusModal}
                            onClose={closeUpdateStatusModal}
                            size="small"
                          >
                            <Modal.Header>
                              Update Status: {project.projectName}
                            </Modal.Header>
                            <Modal.Content>
                              <label style={{ color: "#000" }}>
                                Current Status: {project.projectStatus}
                              </label>
                              <br />
                              <Dropdown
                                placeholder="Select Status"
                                fluid
                                search
                                selection
                                options={projectStatusOptions}
                                required={true}
                                onChange={(e, { value }) => {
                                  // setClient(value);
                                  setProjectStatus(value);
                                }}
                              />
                            </Modal.Content>
                            <Modal.Actions>
                              <Button
                                onClick={closeUpdateStatusModal}
                                color="black"
                              >
                                Close
                              </Button>
                              <Button
                                onClick={handleStatusUpdate}
                                color="primary"
                              >
                                Save Changes
                              </Button>
                            </Modal.Actions>
                          </Modal>

                          <Modal
                            dimmer={"blurring"}
                            basic={true}
                            onClose={handleDeleteModalClose}
                            onOpen={handleDeleteModalOpen}
                            open={deleteModalOpen} // Use separate state for each modal
                            size="small"
                            trigger={
                              <Button
                                color="red"
                                content="Delete"
                                icon="trash"
                                labelPosition="right"
                              />
                            }
                          >
                            <Header icon>
                              <Icon name="archive" />
                              Delete Project: {project.projectName}?
                            </Header>
                            <Modal.Content>
                              <p style={{ textAlign: "center" }}>
                                Are you sure?
                              </p>
                            </Modal.Content>
                            <Modal.Actions>
                              <Button
                                inverted={true}
                                color="red"
                                onClick={() => handleDeleteModalClose()}
                              >
                                <Icon name="remove" /> No
                              </Button>
                              <Button
                                inverted={true}
                                color="green"
                                onClick={async () => {
                                  toast("Project Successfully Deleted", {
                                    type: "success",
                                  });

                                  await sendApiRequest(
                                    "post",
                                    "/projects/delete/" + project.id
                                  );

                                  handleDeleteModalClose();

                                  return navigate(
                                    "/dashboard/projects?msg=Project Successfully Deleted"
                                  );

                                  // await deleteProject(project.id, localStorage.getItem('token'));
                                }}
                              >
                                <Icon name="checkmark" /> Yes
                              </Button>
                            </Modal.Actions>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {user_role === 5 && (
                  <>
                    <div style={{ marginRight: 1, float: "right" }}>
                      <div style={{ display: "flex" }}>
                        <div>
                          <Link to={`/dashboard/projects/view/${project.id}/view-attendance-logs`}>
                            <Button
                              color="yellow"
                              content="View Attendance Status"
                              icon="eye"
                              labelPosition="right"
                            />
                          </Link>
                        </div>
                        <div>
                          <Link to={`/dashboard/projects/view/${project.id}/request-attendance`}>
                            <Button
                              color="primary"
                              content="Request Attendance"
                              icon="pencil"
                              labelPosition="right"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column floated="left" width={10}>
                <Card.Group itemsPerRow={1}>
                  <Card style={{ backgroundColor: "#fff", padding: 25 }} raised>
                    <Card.Content>
                      <Grid>
                        <Grid.Column floated="left" width={13}>
                          <Card.Header>
                            <h2>Project Description / Requirements</h2>
                          </Card.Header>
                        </Grid.Column>
                      </Grid>
                      <Card.Description>
                        <div style={{ paddingTop: 20, paddingBottom: 10 }}>
                          <div dangerouslySetInnerHTML={{ __html: project.projectDesc }} />
                        </div>
                      </Card.Description>
                    </Card.Content>
                  </Card>

                  <Card style={{ backgroundColor: "#fff", padding: 25 }} raised>
                    <Card.Content>
                      <Grid>
                        <Grid.Column floated="left" width={13}>
                          <Card.Header>
                            <h2>Disclaimer</h2>
                          </Card.Header>
                        </Grid.Column>
                      </Grid>
                      <Card.Description>
                        <div style={{ paddingTop: 20, paddingBottom: 10 }}>
                          {project.projectDisclaimerIsSigned === 1 && (
                            <>
                              {project.projectDisclaimerMeta.disclaimerNotification.map((e, index) => (
                                <div style={{ fontSize: "15px", color: "green" }} key={index}><FaCheckCircle color='green' /> {e}</div>
                              ))}
                              <br />
                              <Button
                                style={{ float: "left" }}
                                color="primary"
                                content="View Signed Disclaimer"
                                icon="eye"
                                labelPosition="right"
                                size="mid"
                                onClick={signDisclaimerModalOpenBtn}
                              />
                              <Modal
                                open={openSignDisclaimerModel}
                                onClose={closeSignDisclaimerModel}
                                size="small"
                              >
                                <Modal.Header>
                                  Viewing Signed Disclaimer - {project.projectName}
                                </Modal.Header>
                                <Modal.Content>
                                  <ViewSignedDisclaimerComponent
                                    defaultData={project}
                                    onSubmitFromParent={handleUpdateSignDisclaimer}
                                  />
                                </Modal.Content>
                                <Modal.Actions>
                                  <Button
                                    onClick={closeSignDisclaimerModel}
                                    color="black"
                                  >
                                    Close
                                  </Button>
                                </Modal.Actions>
                              </Modal>
                              {user_role === 1 && project.projectDisclaimerMeta.pdfLink && (
                                <Button
                                  style={{ float: "left" }}
                                  color="green"
                                  content="Download Signed Disclaimer"
                                  icon="download"
                                  labelPosition="right"
                                  size="mid"
                                  href={project.projectDisclaimerMeta.pdfLink}
                                  target="_blank"
                                />
                              )}
                            </>
                          )}

                          {project.projectDisclaimerIsSigned === 0 && (
                            <>
                              <Button
                                style={{ float: "left" }}
                                color="primary"
                                content="Sign Disclaimer"
                                icon="pencil"
                                labelPosition="right"
                                size="mini"
                                onClick={signDisclaimerModalOpenBtn}
                              />
                              <Modal
                                open={openSignDisclaimerModel}
                                onClose={closeSignDisclaimerModel}
                                size="small"
                              >
                                <Modal.Header>
                                  Sign Disclaimer - {project.projectName}
                                </Modal.Header>
                                <Modal.Content>
                                  <SignDisclaimerComponent
                                    defaultData={project}
                                    onSubmitFromParent={handleUpdateSignDisclaimer}
                                  />
                                </Modal.Content>
                                <Modal.Actions>
                                  <Button
                                    onClick={closeSignDisclaimerModel}
                                    color="black"
                                  >
                                    Close
                                  </Button>
                                </Modal.Actions>
                              </Modal>
                            </>
                          )}

                        </div>
                      </Card.Description>
                    </Card.Content>
                  </Card>

                  <Card style={{ backgroundColor: "#fff", padding: 25 }} raised>
                    <ProjectImageCardWithModal
                      id={project.id}
                      images={projectImages}
                      onUploadComplete={handleUploadComplete}
                    />
                  </Card>

                  <Card style={{ backgroundColor: "#fff", padding: 25 }} raised>
                    <Card.Content>
                      <Grid>
                        <Grid.Column floated="left" width={9}>
                          <Card.Header>
                            <h2>Job Report</h2>
                          </Card.Header>
                        </Grid.Column>

                        <Grid.Row>
                          <Grid.Column floated="left" width={7}>
                            {projectReportLogs && projectReportLogs.status == 'approved' && (
                              <>
                                <FaDownload size="2em" color="green" /> &nbsp;
                                {project.projectReportLogs && (
                                  project.projectReportLogs.map((log) => (
                                    <a href={log.pdf_link} target="_blank">
                                      <b color="green" style={{ color: 'green', fontSize: '16px' }} key={log.date}>JOB REPORT {formatToBritishFormat(log.date)}.pdf</b>
                                    </a>
                                  ))
                                )}
                              </>
                            )}
                          </Grid.Column>
                          <Grid.Column floated="right" width={6}>
                            <Card.Header>
                              {(!projectReportLogs || projectReportLogs && projectReportLogs.status === 'review') && (
                                <>
                                  {(user.role === 1 || user.role === 2 || user.role === 3) && (
                                    <>

                                      <Button
                                        style={{ float: "right", color: "#000" }}
                                        color="yellow"
                                        content="Upload Manual Report"
                                        icon="upload"
                                        labelPosition="right"
                                        size="mini"
                                        onClick={uploadJobReportModalOpenBtn}
                                      />
                                      <br /><br />
                                      <Button
                                        style={{ float: "right" }}
                                        color="primary"
                                        content="Add / Update Report"
                                        icon="plus"
                                        labelPosition="right"
                                        size="mini"
                                        onClick={JobReportModalOpenBtn}
                                      />
                                      <br /><br />
                                      <Button
                                        style={{ float: "right" }}
                                        color="secondary"
                                        content="View Job Report Logs"
                                        icon="eye"
                                        labelPosition="right"
                                        size="mini"
                                        onClick={ViewJobReportModalOpenBtn}
                                      />
                                      <Modal
                                        open={openJobModal}
                                        onClose={closeJobReportModal}
                                        size="small"
                                      >
                                        <Modal.Header>
                                          Add/Update Report: {project.projectName}
                                        </Modal.Header>
                                        <Modal.Content>
                                          <UpdateJobReportModal
                                            defaultData={project}
                                            onSubmitFromParent={handleUpdateJobReport}
                                          />
                                        </Modal.Content>
                                        <Modal.Actions>
                                          <Button
                                            onClick={closeJobReportModal}
                                            color="black"
                                          >
                                            Close
                                          </Button>
                                        </Modal.Actions>
                                      </Modal>


                                      <Modal
                                        open={openViewJobReportModal}
                                        onClose={closeViewJobReportModal}
                                        size="small"
                                      >
                                        <Modal.Header>
                                          Viewing Project Report Log - {project.projectName}
                                        </Modal.Header>
                                        <Modal.Content>
                                          <ViewJobReportLogsModal
                                            project={project}
                                            defaultData={project.projectReportLogs || null}
                                            onSubmitFromParent={handleUpdateJobReportLogs}
                                          />
                                        </Modal.Content>
                                        <Modal.Actions>
                                          <Button
                                            onClick={closeViewJobReportModal}
                                            color="black"
                                          >
                                            Close
                                          </Button>
                                        </Modal.Actions>
                                      </Modal>

                                      <Modal
                                        open={uploadJobReportModalOpen}
                                        onClose={closeUploadJobReportModal}
                                        size="small"
                                      >
                                        <Modal.Header>
                                          Upload Manual Project Report Log - {project.projectName}
                                        </Modal.Header>
                                        <Modal.Content>
                                          <Form loading={loading}>
                                            <Modal.Content>
                                              <Dropzone
                                                onChange={updateFiles}
                                                value={files}
                                                maxFileSize={8 * 1024 * 1024}
                                                accept=".pdf"
                                              >
                                                {files.map((file) => (
                                                  <FileMosaic
                                                    key={file.id}
                                                    {...file}
                                                    onDelete={removeFile}
                                                    info
                                                    preview={true}
                                                    progress={true}
                                                    
                                                  />
                                                ))}
                                              </Dropzone>
                                            </Modal.Content>
                                          </Form>
                                          <br />
                                          <Modal.Actions>
                                            <Button onClick={closeUploadJobReportModal} color="black">
                                              Close
                                            </Button>

                                            {loading === false && (
                                              <>
                                                <Button onClick={handleUpload} color="primary">
                                                  Upload
                                                </Button>
                                              </>
                                            )}
                                          </Modal.Actions>
                                          </Modal.Content>
                                      </Modal>

                                    </>
                                  )}
                                </>

                              )}
                            </Card.Header>
                          </Grid.Column>
                        </Grid.Row>



                      </Grid>
                      <Card.Description>
                        <div style={{ paddingTop: 20, paddingBottom: 10 }}>
                          <List>
                            <List.Item>
                              <List.Content>

                              </List.Content>
                            </List.Item>
                          </List>
                          <Card.Header>
                            {(!projectReportLogs || projectReportLogs && projectReportLogs.status === 'review') && (
                              <>
                                {project.projectInvoiceMeta == null && (
                                  <>
                                    {user.role === 1 && (
                                      <>
                                        <Button
                                          style={{ float: "right" }}
                                          color="green"
                                          content="Create Invoice"
                                          icon="plus"
                                          labelPosition="right"
                                          size="mini"
                                          onClick={CreateInvoiceModalOpenBtn}
                                        />
                                        <Modal
                                          open={openCreateInvoiceModal}
                                          onClose={closeCreateInvoiceModalOpen}
                                          size="small"
                                        >
                                          <Modal.Header>
                                            Create Invoice - {project.projectName}
                                          </Modal.Header>
                                          <Modal.Content>
                                            <CreateInvoiceModalComponent
                                              defaultData={project}
                                              onSubmitFromParent={handleCreateInvoiceModal}
                                            />
                                          </Modal.Content>
                                          <Modal.Actions>
                                            <Button
                                              onClick={closeCreateInvoiceModalOpen}
                                              color="black"
                                            >
                                              Close
                                            </Button>
                                          </Modal.Actions>
                                        </Modal>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                            <br />
                            {user.role === 1 && (
                              <>
                                {project.projectInvoiceMeta !== null && (
                                  <>
                                    <h4 className={"primary-color"}>Invoice [{project.projectName}]</h4>
                                    <h4 className={"primary-color"}>Project Estimate: £{formatM(project.projectInvoiceMeta?.estimate)}</h4>
                                    <h4 className={"primary-color"}>Balance: £{formatM(project.projectInvoiceMeta?.estimate - (project.projectInvoiceMeta?.logs.reduce((total, log) => total + parseFloat(log.cost), 0)))}</h4>
                                    <Link to={'/dashboard/projects/invoice/logs/' + project.id}>
                                      <Button
                                        style={{ float: "right" }}
                                        color="primary"
                                        content="Log Entries +"
                                        icon="plus"
                                        labelPosition="right"
                                        size="mini"
                                      />
                                    </Link>
                                  </>
                                )}
                                <br /><br />
                                <ProjectInvoiceLogsTable reports={project.projectInvoiceMeta?.logs} />
                              </>
                            )}


                          </Card.Header>
                        </div>
                      </Card.Description>
                    </Card.Content>
                  </Card>


                  <Card style={{ backgroundColor: "#fff", padding: 25 }} raised>
                    <Card.Content>
                      <Grid>
                        <Grid.Column floated="left" width={9}>
                          <Card.Header>
                            <h2>Landlord / Owner Gas Safety Record</h2>
                          </Card.Header>
                        </Grid.Column>

                        <Grid.Row>
                          <Grid.Column floated="left" width={7}>
                            {projectLandlordCertLogs && projectLandlordCertLogs.status == 'approved' && (
                              <>
                                <FaDownload size="2em" color="green" /> &nbsp;
                                {project.projectLandlordCertLogs && (
                                  project.projectLandlordCertLogs.map((log) => (
                                    <a href={log.pdf_link}>
                                      <b color="green" style={{ color: 'green', fontSize: '16px' }} key={log.date}>LANDLORD CERT {formatToBritishFormat(log.date)}.pdf</b>
                                    </a>
                                  ))
                                )}
                              </>
                            )}


                          </Grid.Column>
                          <Grid.Column floated="right" width={6}>
                            <Card.Header>
                              <>
                                {(!projectLandlordCertLogs || projectLandlordCertLogs && projectLandlordCertLogs.status === 'review') && (
                                  <>
                                    {(user.role === 1 || user.role === 2 || user.role === 3) && (
                                      <>

                                        <Link to={"/dashboard/project/landlord/add-record/" + project.id}>
                                          <Button
                                            style={{ float: "right" }}
                                            color="primary"
                                            content="Add / Update Record +"
                                            icon="plus"
                                            labelPosition="right"
                                            size="mini"
                                          />
                                        </Link>

                                        <br /><br />
                                        <Button
                                          style={{ float: "right" }}
                                          color="orange"
                                          content="View Logs"
                                          icon="eye"
                                          labelPosition="right"
                                          size="mini"
                                          onClick={ViewLandlordCertModalOpenBtn}
                                        />
                                        <Modal
                                          open={openLandlordCertModal}
                                          onClose={closeLandlordCertModal}
                                          size="small"
                                        >
                                          <Modal.Header>
                                            Add/Update Report: {project.projectName}
                                          </Modal.Header>
                                          <Modal.Content>
                                            <UpdateJobReportModal
                                              defaultData={project}
                                              onSubmitFromParent={handleUpdateLandlordCert}
                                            />
                                          </Modal.Content>
                                          <Modal.Actions>
                                            <Button
                                              onClick={closeLandlordCertModal}
                                              color="black"
                                            >
                                              Close
                                            </Button>
                                          </Modal.Actions>
                                        </Modal>


                                        <Modal
                                          open={openViewLandlordCertModal}
                                          onClose={closeViewLandlordCertModal}
                                          size="small"
                                        >
                                          <Modal.Header>
                                            Viewing Project Landlord Cert Log - {project.projectName}
                                          </Modal.Header>
                                          <Modal.Content>
                                            <ViewProjectLandlordCertLogs
                                              project={project}
                                              defaultData={project.projectLandlordCertLogs || null}
                                              onSubmitFromParent={handleUpdateLandlordCertLogs}
                                            />
                                          </Modal.Content>
                                          <Modal.Actions>
                                            <Button
                                              onClick={closeViewLandlordCertModal}
                                              color="black"
                                            >
                                              Close
                                            </Button>
                                          </Modal.Actions>
                                        </Modal>
                                      </>
                                    )}
                                  </>
                                )}
                              </>


                            </Card.Header>
                          </Grid.Column>
                        </Grid.Row>



                      </Grid>

                    </Card.Content>
                  </Card>

                  {project.send_client_notif === 1 && (
                    <Card style={{ backgroundColor: "#fff", padding: 25 }} raised>
                      <Card.Content>
                        <Grid>
                          <Grid.Column floated="left" width={9}>
                            <Card.Header>
                              <h2>Email / SMS Notification Log</h2>
                            </Card.Header>
                          </Grid.Column>
                          <Grid.Column floated="right" width={6}>
                            <Card.Header>

                            </Card.Header>
                          </Grid.Column>
                        </Grid>
                        <Card.Description>
                          <div style={{ paddingTop: 20, paddingBottom: 10 }}>
                            <List>
                              <List.Item>
                                <List.Content>
                                  <ProjectNotificationLogs
                                    projectNotifications={project.projectClientNotifications}
                                  />
                                </List.Content>
                              </List.Item>
                            </List>
                            <Card.Header>
                            </Card.Header>
                          </div>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  )}
                </Card.Group>
              </Grid.Column>
              <Grid.Column floated="right" width={6}>
                <Card
                  style={{ backgroundColor: "#fff", padding: 10 }}
                  className="project-right-card"
                  raised
                >
                  <Card.Content>
                    <Grid>
                      <Grid.Column floated="left" width={13}>
                        <Card.Header>
                          <h2>Project Activity</h2>
                        </Card.Header>
                      </Grid.Column>
                    </Grid>
                    <Card.Description>
                      <ProjectActivities
                        projectActivities={project.projectActivities}
                      />
                    </Card.Description>
                  </Card.Content>
                </Card>
                <Card
                  style={{ backgroundColor: "#fff", padding: 10 }}
                  className="project-right-card"
                  raised
                >
                  <Card.Content>
                    <Grid>
                      <Grid.Column floated="left" width={13}>
                        <Card.Header>
                          <h2>Project Milestone</h2>
                        </Card.Header>
                      </Grid.Column>
                    </Grid>
                    <Card.Description>
                      <ProjectMilestones
                        projectMilestones={project.projectMilestones}
                      />
                    </Card.Description>
                  </Card.Content>
                </Card>
                <div style={{ border: "1px solid #ddd" }}>
                  <div className="primary-color-bg padding text-white">
                    Project Chat
                  </div>
                  <ChatWidget
                    id={project.id}
                    messages={messages}
                    user={user}
                    updateMessages={updateMessages}
                  />
                </div>

                {project.clientId > 0 && (
                  <>
                    <Card
                      style={{ backgroundColor: "#fff", padding: 10 }}
                      className="project-right-card"
                      raised
                    >
                      <ProjectSchematics
                        id={project.id}
                        projectSchematics={project.projectSchematics}
                        onUploadComplete={handleSchematicsUploadComplete}
                      />
                    </Card>
                  </>
                )}

                {/* <Card
                  style={{ backgroundColor: "#fff", padding: 10 }}
                  className="project-right-card"
                  raised
                >
                  <ProjectInvoices
                    id={project.id}
                    projectInvoices={project.projectInvoices}
                    onUploadComplete={handleInvoiceUploadComplete}
                  />
                </Card> */}
              </Grid.Column>
            </Grid>
          </div>
        </Form>
      </AdminDashLayout>
    </>
  );
}

export default ViewProject;
