import React, { useState, useEffect } from 'react';
import AdminDashLayout from '../components/AdminDashLayout';
import ChatInterface from '../components/ChatInterface';
import sendApiRequest from '../api';
import { Modal, Button, Input, Grid } from 'semantic-ui-react';

function ClientSupportMessenger() {
    const [data, setData] = useState({});
    const [openNewchatModal, setOpenNewChatModal] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [editingName, setEditingName] = useState(false);
    const [tempName, setTempName] = useState('');
    const [selectedContact, setSelectedContact] = useState(null);
    const [currentConversation, setCurrentConversation] = useState(null);

    useEffect(() => {
        // Initialize client list
        sendApiRequest('get', '/clients').then((response) => {
            setData({ contacts: response.data });
        }).catch(error => {
            console.error('Error fetching clients:', error);
        });
    }, []);

    useEffect(() => {
        let intervalId;

        const fetchConversation = async () => {
            if (selectedContact) {
                try {
                    const response = await sendApiRequest('get', `/messaging/conversation/${selectedContact.id}`);
                    if (response.success) {
                        setCurrentConversation(response.data);
                    }
                } catch (error) {
                    console.error('Error fetching conversation:', error);
                }
            }
        };

        // Initial fetch
        fetchConversation();

        // Set up polling every 3 seconds
        if (selectedContact) {
            intervalId = setInterval(fetchConversation, 3000);
        }

        // Cleanup interval on unmount or when selectedContact changes
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [selectedContact]);

    const handleSubmit = async () => {
        if (validateForm()) {
            try {
                // Create new chat with initial message
                const response = await sendApiRequest('post', '/messenger/client/new', {
                    phoneNumber: phoneNumber,
                    message: message,
                    clientName: phoneNumber // Using phone number as initial client name
                });

                // Check if contact already exists in the list
                const existingContactIndex = data.contacts?.findIndex(contact => 
                    contact.clientPhone === phoneNumber
                );

                if (existingContactIndex !== -1) {
                    // If contact exists, select it
                    setSelectedContact(data.contacts[existingContactIndex]);
                    setCurrentConversation(response.data);
                } else {
                    // If it's a new contact, add it to the beginning of the list
                    setData(prevData => ({
                        contacts: [response.data.client, ...(prevData.contacts || [])]
                    }));
                    setSelectedContact(response.data.client);
                    setCurrentConversation(response.data);
                }

                setPhoneNumber('');
                setMessage('');
                setErrors({});
                setOpenNewChatModal(false);
            } catch (error) {
                console.error('Error creating new chat:', error);
                setErrors({ submit: 'Failed to create chat. Please try again.' });
            }
        }
    };

    const handleSendMessage = async (chatId, message) => {
        try {
            const response = await sendApiRequest('post', '/messaging/add-message', {
                conversationId: chatId,
                message: message
            });
            
            // Update the current conversation with the new message
            const updatedConversation = response.data;
            setCurrentConversation(updatedConversation);
            //setSelectedContact(updatedConversation)

            // Update the contact list to reflect any changes
            // setData(prevData => ({
            //     contacts: prevData.contacts.map(contact => 
            //         contact.id === chatId ? {
            //             ...contact,
            //             lastMessage: message,
            //             updatedAt: new Date().toISOString()
            //         } : contact
            //     )
            // }));
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    const handleUpdateName = async (chatId, newName) => {
        try {
            const response = await sendApiRequest('post', `/messenger/client/nickname`, {
                clientId: chatId,
                nickname: newName
            });

            const updatedChat = response.data;
            setData(prevData => ({
                contacts: prevData.contacts.map(contact => 
                    contact.id === chatId ? updatedChat : contact
                )
            }));

            if (selectedContact?.id === chatId) {
                setSelectedContact(updatedChat);
                setCurrentConversation(updatedChat);
            }
            setEditingName(false);
            setTempName('');
        } catch (error) {
            console.error('Error updating chat name:', error);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!phoneNumber) {
            newErrors.phoneNumber = 'Phone number is required';
        } 
        if (!message.trim()) {
            newErrors.message = 'Message is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleDeleteMessage = async (messageId) => {
        try {
            await sendApiRequest('post', `/messaging/message/${messageId}`);
            if (selectedContact) {
                const response = await sendApiRequest('get', `/messaging/conversation/${selectedContact.id}`);
                if (response.success) {
                    setCurrentConversation(response.data);
                }
            }
        } catch (error) {
            console.error('Error deleting message:', error);
        }
    };

    const handleDeleteAllMessages = async (conversationId) => {
        if (!conversationId) return;
        try {
            await sendApiRequest('post', `/messaging/conversation/${conversationId}/messages`);
            if (selectedContact) {
                const response = await sendApiRequest('get', `/messaging/conversation/${selectedContact.id}`);
                if (response.success) {
                    setCurrentConversation(response.data);
                }
            }
        } catch (error) {
            console.error('Error deleting all messages:', error);
        }
    };

    return (
        <AdminDashLayout>
            <br /><br />
            <Grid>
                <Grid.Column floated="left" width={7}>
                    <h1>GD SUPPORT SYSTEM</h1>
                    <h3 style={{ color: "#044f66", marginTop: 10, fontSize: 20 }}>
                        Welcome to the GD support system.
                    </h3>
                </Grid.Column>
            </Grid>
            <ChatInterface 
                contacts={data.contacts} 
                onSendMessage={handleSendMessage}
                onNewChat={() => setOpenNewChatModal(true)}
                onUpdateName={handleUpdateName}
                editingName={editingName}
                setEditingName={setEditingName}
                tempName={tempName}
                setTempName={setTempName}
                selectedContact={selectedContact}
                setSelectedContact={setSelectedContact}
                currentConversation={currentConversation}
                onDeleteMessage={handleDeleteMessage}
                onDeleteAllMessages={handleDeleteAllMessages}
            />
            <br /><br />

            <Modal
                open={openNewchatModal}
                onClose={() => setOpenNewChatModal(false)}
                size="small"
            >
                <Modal.Header>Start A New Chat</Modal.Header>
                <Modal.Content>
                    <div style={{ padding: '20px' }}>
                        <div style={{ marginBottom: '20px' }}>
                            <label>Phone Number</label>
                            <Input
                                fluid
                                placeholder="Enter phone number"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                error={errors.phoneNumber ? true : false}
                            />
                            {errors.phoneNumber && (
                                <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                    {errors.phoneNumber}
                                </div>
                            )}
                        </div>

                        <div style={{ marginBottom: '20px' }}>
                            <label>Message</label>
                            <textarea
                                style={{
                                    width: '100%',
                                    minHeight: '100px',
                                    padding: '10px',
                                    marginTop: '5px',
                                    border: errors.message ? '1px solid red' : '1px solid #ddd',
                                    borderRadius: '4px'
                                }}
                                placeholder="Type your message here"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                            {errors.message && (
                                <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                    {errors.message}
                                </div>
                            )}
                        </div>

                        <Button primary fluid onClick={handleSubmit}>
                            Send Message
                        </Button>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpenNewChatModal(false)} color="black">
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        </AdminDashLayout>
    );
}

export default ClientSupportMessenger;