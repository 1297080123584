import React, { useEffect, useState, forwardRef } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Button, Form, Breadcrumb, TextArea, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import addWeeks from "date-fns/addWeeks";
import sendApiRequest from "../api"; // Import your API request function
import AuthService from "../auth";
import {formatToYYYYMMDD} from "../utils";
import { toast } from "react-toastify";

const RequestHoliday = () => {
    const user = AuthService.getCurrentUser();

    const [leaveStartDate, setLeaveStartDate] = useState(null); // Start date
    const [leaveEndDate, setLeaveEndDate] = useState(null); // End date
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const holidayWeeks = user.holiday_days || 12; // Replace with the pre-set holiday weeks
    const [reason, setReason] = useState("");

    const [apiErrors, setApiErrors] = useState(null);
    const [apiErrorMessage, setApiErrorMessage] = useState("");
    const [apiSuccess, setApiSuccess] = useState(false);
    const [apiSuccessMessage, setApiSuccessMessage] = useState("");

    useEffect(() => {
        // Calculate the end date based on the start date and pre-set holiday weeks
        if (leaveStartDate) {
            const endDate = addWeeks(leaveStartDate, holidayWeeks);
            setLeaveEndDate(endDate);
        } else {
            setLeaveEndDate(null); // Clear the end date if start date is not set
        }
    }, [leaveStartDate, holidayWeeks]);

    const handleSubmit = async function () {
        // Reset errors and API messages
        setErrors({});
        setApiErrors(null);
        setApiErrorMessage("");
        setApiSuccess(false);
        setApiSuccessMessage("");

        if (!reason) {
            setErrors({ reason: "Please enter the reason for your holiday request." });
            return;
        }

        // Handle form submission logic here
        setLoading(true);

        // Example: Send the form data to the server
        const formData = {
            request: reason,
            start_date: formatToYYYYMMDD(leaveStartDate),
            return_date: formatToYYYYMMDD(leaveEndDate),
        };

        const userHolidayDays = user.holiday_days;

        // Convert date strings to Date objects
        const date1 = new Date(leaveStartDate);
        const date2 = new Date(leaveEndDate);

        // Calculate the time difference in milliseconds
        const timeDifference = date2.getTime() - date1.getTime();

        // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
        const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

        try {
            // Send the formData to the server using your API request function
            await sendApiRequest(
                "post",
                "/workers/holiday_requests/create",
                formData,
                localStorage.getItem("token")
            );

            // Handle success response
            setApiSuccess(true);
            setLoading(false)
            setApiSuccessMessage("Success!");
            toast("Success!")
        } catch (error) {
            console.log(error)
            // Handle error response
            setApiErrors(true);
            setApiErrorMessage("Failed to submit holiday request. Please try again later.");
        }

        // Simulate loading completion after a delay (remove in a real app)
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button type="button" className="example-custom-input" style={{ width: "100%", height: "37px", background: "#fff", color: "#000", textAlign: "left", border: "1px solid #ddd" }} onClick={onClick} ref={ref}>
          {value}
        </button>
      ));
    

    return (
        <>
            <AdminDashLayout>
                <div style={{ paddingTop: 40 }}>
                    <Breadcrumb>
                        <Link to={"/dashboard"}>
                            <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                        </Link>
                        <Breadcrumb.Divider icon="right arrow" />
                        <Breadcrumb.Section>Request Holiday</Breadcrumb.Section>
                    </Breadcrumb>
                </div>
                <Form loading={loading}>
                {apiErrors ? (
                                <Message
                                    error
                                    header="Whoops!"
                                    list={apiErrorMessage}
                                />
                            ) : null}

                            {apiSuccess ? (
                                <Message
                                    success
                                    header="Success!"
                                    content={apiSuccessMessage}
                                />
                            ) : null}
                    <br />
                    <label>Enter Holiday Reason</label>
                    <TextArea
                        placeholder={
                            "What Holiday Are You Going On ? (eg: leave, christmas, easter etc)"
                        }
                        size="medium"
                        required={true}
                        error={errors.reason ? { content: errors.reason } : null}
                        onChange={(e) => {
                            setErrors({});
                            setReason(e.target.value);
                        }}
                    ></TextArea>
                    <br />
                    <br />
                    <label>First Date Of Holiday</label>
                    <DatePicker
                        selected={leaveStartDate}
                        onChange={(date) => setLeaveStartDate(date)}
                        customInput={<ExampleCustomInput />}
                        dateFormat="dd/MM/yyyy"
                    />
                    <br />
                    <br />
                    <label>Last Date Of Holiday</label>
                    <DatePicker
                        selected={leaveEndDate}
                        onChange={(date) => setLeaveEndDate(date)}
                        customInput={<ExampleCustomInput />}
                        dateFormat="dd/MM/yyyy"
                    />
                    <br />
                    <br />
                    <Button
                        size="midi"
                        color="primary"
                        content="Submit Request"
                        icon="send"
                        labelPosition="right"
                        onClick={handleSubmit}
                    />
                    {apiErrors ? (
                        <Message error header="Whoops!" list={[apiErrorMessage]} />
                    ) : null}
                    {apiSuccess ? (
                        <Message success header="Success!" content={apiSuccessMessage} />
                    ) : null}
                </Form>

            </AdminDashLayout>
        </>
    );
};

export default RequestHoliday;
