import React, { useState, useRef, useEffect } from 'react';
import { Icon, Input } from 'semantic-ui-react';
import { ClientAvatar } from './ClientAvatar';
import '../styles/ChatInterface.css';
import Avatar from 'react-avatar';
import { formatRelativeTime } from '../utils';
import AuthService from "../auth";

const ChatInterfaceTenants = ({
    contacts,
    onSendMessage,
    onNewChat,
    onUpdateName,
    editingName,
    setEditingName,
    tempName,
    setTempName,
    selectedContact,
    setSelectedContact,
    currentConversation,
    onDeleteMessage,
    onDeleteAllMessages
}) => {
    // Remove the local selectedContact state since we're using props
    const [message, setMessage] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const messagesEndRef = useRef(null);
    const [showSidebar, setShowSidebar] = useState(window.innerWidth > 768);
    const sidebarRef = useRef(null);

    const user = AuthService.getCurrentUser();
    const user_role = user.role;

    useEffect(() => {
        const handleResize = () => {
            setShowSidebar(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [currentConversation?.messages]);

    const handleSend = () => {
        if (message.trim() && selectedContact) {
            onSendMessage(selectedContact.clientPhone, message);
            setMessage('');
        }
    };

    const filteredContacts = contacts?.filter(contact =>
        contact.clientName?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current &&
                !sidebarRef.current.contains(event.target) &&
                window.innerWidth <= 768) {
                setShowSidebar(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleContactSelect = (contact) => {
        setSelectedContact(contact);
        if (window.innerWidth <= 768) {
            setShowSidebar(false);
        }
    };

    return (
        <div className="chat-layout">
            <div className={`chat-sidebar ${showSidebar ? 'active' : ''}`} ref={sidebarRef}>
                <div className="chat-search">
                    <Input
                        icon="search"
                        placeholder="Search contacts..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="chat-search-input"
                    />
                </div>
                <div className="chat-contacts">
                    <ul className="contact-list">
                        {filteredContacts?.map((contact) => (
                            <li
                                key={contact.id}
                                className={`contact-item ${selectedContact?.id === contact.id ? 'active' : ''}`}
                                onClick={() => handleContactSelect(contact)}
                            >
                                <div className="contact-avatar">
                                    <Avatar name={contact.clientName} size='40' round />
                                </div>
                                <div className="contact-info">
                                    <div className="contact-name">{contact.clientName}</div>

                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="chat-main">
                <div className="chat-header">
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                        {selectedContact ? (
                            <>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <button
                                        className="mobile-menu-button"
                                        onClick={() => setShowSidebar(!showSidebar)}
                                    >
                                        <Icon name="bars" />
                                    </button>
                                    <Avatar name={selectedContact.clientName} size="40" round />
                                    <div className="contact-info" style={{ marginLeft: '12px' }}>
                                        <div className="contact-name">
                                            {selectedContact.clientName || tempName} ({selectedContact.property_name})
                                            {selectedContact.clientName === selectedContact.clientPhone && (
                                                <>
                                                    {editingName ? (
                                                        <div style={{ display: 'inline-flex', marginLeft: '10px', alignItems: 'center' }}>
                                                            <Input
                                                                size="mini"
                                                                placeholder="Enter name"
                                                                value={tempName}
                                                                onChange={(e) => setTempName(e.target.value)}
                                                                onKeyPress={(e) => {
                                                                    if (e.key === 'Enter' && tempName.trim()) {
                                                                        onUpdateName(selectedContact.id, tempName.trim());
                                                                    }
                                                                }}
                                                            />
                                                            <Icon
                                                                name="check"
                                                                style={{ marginLeft: '5px', cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    if (tempName.trim()) {
                                                                        onUpdateName(selectedContact.id, tempName.trim());
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <span
                                                            style={{ marginLeft: '10px', color: '#044f66', cursor: 'pointer' }}
                                                            onClick={() => setEditingName(true)}
                                                        >
                                                            (Set nickname)
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                        <div className="contact-status">
                                            {selectedContact.clientEmail || ''} {'| ' + selectedContact.clientPhone || ''}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                <button
                                    className="mobile-menu-button"
                                    onClick={() => setShowSidebar(!showSidebar)}
                                >
                                    <Icon name="bars" />
                                </button>
                                <div>Select a contact to start chatting</div>
                            </div>
                        )}

                        {user_role === 1 && (
                            <>
                                <div style={{ display: 'flex', gap: '10px' }}>
                                    <button className="chat-button" onClick={() => onDeleteAllMessages(currentConversation?.id)}>
                                        <Icon name="trash" style={{ color: '#ff4d4f' }} />
                                    </button>
                                </div>
                            </>
                        )}


                    </div>
                </div>

                <div className="chat-messages">
                    {currentConversation?.messages?.map((msg, index) => {
                        const colors = ['#CBF3B1', '#DC7EAF', '#DCB793', '#87D9FF', '#7277FB', '#F3E8AA', '#DAE2E2', '#68CFA6'];
                        const colorIndex = index % colors.length;
                        const messageStyle = msg.direction === 'inbound' ? { backgroundColor: colors[colorIndex] } : {};

                        return (
                            <div
                                key={index}
                                className={`message ${msg.direction === 'outbound' ? 'message-outgoing' : 'message-incoming'}`}

                            >
                                <div className="message-time">
                                    {msg.created_at ? formatRelativeTime(new Date(msg.created_at).toLocaleString()) : 'Just now'}
                                </div>
                                <div className="message-content" style={messageStyle}>
                                    <div dangerouslySetInnerHTML={{ __html: msg.message }} />

                                </div>

                                {user_role === 1 && (
                                    <>
                                        <Icon
                                            name="trash"
                                            style={{
                                                marginLeft: '8px',
                                                cursor: 'pointer',
                                                color: '#ff4d4f'
                                            }}
                                            onClick={() => onDeleteMessage(msg.id)}
                                        />
                                    </>
                                )}

                            </div>
                        );
                    })}
                    <div ref={messagesEndRef} />
                </div>

                <div className="chat-input-container">
                    <div className="chat-input-wrapper">
                        <input
                            type="text"
                            className="chat-input"
                            placeholder="Type a message..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                        />
                        <button className="chat-button" onClick={handleSend}>
                            <Icon name="send" /> Send
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatInterfaceTenants;