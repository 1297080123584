import React, { useState, useRef, useEffect } from "react";
import { Form, Icon, Modal, Button } from "semantic-ui-react";
import AuthService from "../auth";
import { formatRelativeTime } from "../utils";
import sendApiRequest from "../api";
import { UserAvatarProjects } from "./UserAvatarProjects";
import { uploadFiles } from "../apiCalls";
import { toast } from "react-toastify";
import { Dropzone, FileMosaic } from "@files-ui/react";

const ChatWidgetMessenger = ({ id, messages, onNewMessage }) => {
  const [newMessage, setNewMessage] = useState("");

  const chatRef = useRef(null);
  const user = AuthService.getCurrentUser();

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [AddmodalIsOpen, setaddModalIsOpen] = useState(false);

  const [currentFileIndex, setCurrentFileIndex] = useState(0);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {

    setaddModalIsOpen(false);
  };

  const openAddModal = () => setaddModalIsOpen(true);
  const closeAddModal = () => setaddModalIsOpen(false);

  const isPdf = (file) => {
    if (file && typeof file === "string") {
      const fileName = file.split("/").pop();
      return fileName.endsWith(".pdf");
    }
    return false;
  };

  const updateFiles = (incomingFiles) => {
    // Remove existing files with the same name and size from incomingFiles to avoid duplicates
    const filteredFiles = incomingFiles.filter((incomingFile) => {
      return !files.some(
        (existingFile) =>
          existingFile.name === incomingFile.name &&
          existingFile.size === incomingFile.size
      );
    });

    // Set the new files (including the filtered incoming files)
    setFiles([...files, ...filteredFiles]);
  };

  const removeFile = (fileId) => {
    // Remove a file from the state
    const updatedFiles = files.filter((file) => file.id !== fileId);
    setFiles(updatedFiles);
  };

  const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];

  const isImage = (file) => {
    if (file && typeof file === "string") {
      const extension = file.split(".").pop();
      return imageExtensions.includes(`.${extension}`);
    }
    return false;
  };

  const handleUpload = async () => {
    try {
      if (files.length > 0) {
        setLoading(true);
        // Upload files and set the photoUri
        const uploadData = await uploadFiles(files);

        const attachments = [];

        for (const item of uploadData.data) {
          attachments.push(item.urls.path);

          let newMessage = "";
        
          const fileName = item.urls.path.split("/").pop();
          const fileExtension = fileName.split(".").pop().toLowerCase();

          if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
            // Image file
            newMessage = `<a href='${item.urls.path}'><img src='${item.urls.path}' target='_blank' width='250' height='90'></a>`;
          } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
            // Video file
            newMessage = `<a href='${item.urls.path}' target='_blank'><video src='${item.urls.path}' width='250' height='90'></video></a>`;
          } else if (fileExtension === "pdf") {
            // PDF file
            newMessage = `<a href='${item.urls.path}' target='_blank'>[Download: ${fileName}]</a>`;
          } else {
            // Other file types
            newMessage = `<a href='${item.urls.path}' target='_blank'>[Download: ${fileName}]</a>`;
          }

          const d = {
            chat_id: id,
            message: newMessage,
            attachments: attachments,
          };

          await sendApiRequest("post", "/messenger/send", d).then((r) => {
            //console.log(r);
          });

          const newMessageData = {
            name: user.firstname,
            text: newMessage,
            staff_photo_uri: user.staff_photo_uri,
            sender: "bot", // Assuming user.firstname is the name
            time: new Date(),
          };

          // Call the callback to update the parent component's messages
          onNewMessage(newMessageData);
        }

        // setChatMessages((prevMessages) => [...prevMessages, newMessageData]);

        // Reset the input field
        setNewMessage("");

        toast("Upload completed");

        // Clear the uploaded files
        setFiles([]);

        closeModal();
        setLoading(false);
      } else {
        toast("Kindly choose a file");
      }

      //   if (files.length > 0) {
      //     // Upload files and get the URLs
      //     const uploadData = await uploadFiles(files);
      //     const uploadedUrls = uploadData.data.map((fileInfo) => ({
      //       file_name: fileInfo.file_name,
      //       link: fileInfo.file_name ? `${fileInfo.urls.path}` : "",
      //     }));

      //     // Check if projectSchematics is defined or initialize it as an empty array
      //     const updatedSchematics = projectSchematics
      //       ? [...projectSchematics, ...uploadedUrls]
      //       : uploadedUrls;

      //     // Notify the parent component that the upload is complete with the updated schematics
      //     await updateSchematics(id, updatedSchematics);

      //     // Clear the uploaded files
      //     setFiles([]);

      //     // Close the modal
      //     closeModal();
      //   }
    } catch (error) {
      // Handle errors here, e.g., show an error message to the user
      console.error("Error uploading files:", error);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() !== "") {
      const d = {
        chat_id: id,
        message: newMessage,
      };

      // console.log(d)

      await sendApiRequest("post", "/messenger/send", d).then((r) => {
        console.log(r);
        // setMessages(cd.messages)
        // setSelectedContact(data.contacts[0])
      });

      const newMessageData = {
        name: user.firstname,
        text: newMessage,
        staff_photo_uri: user.staff_photo_uri,
        sender: "bot", // Assuming user.firstname is the name
        time: new Date(),
      };

      // Call the callback to update the parent component's messages
      onNewMessage(newMessageData);

      // setChatMessages((prevMessages) => [...prevMessages, newMessageData]);

      // Reset the input field
      setNewMessage("");

      // Handle sending newMessages to server or updating state
    }
  };

  // Use useEffect to scroll to the bottom of the chat when messages change
  useEffect(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="chat-container-messenger">
      <div style={{ display: "flex", flexDirection: "column", height: "100%", backgroundColor: "#fff" }}>
        <div style={{ flex: 1, overflowY: "auto", padding: "20px" }} ref={chatRef}>
          {messages && (
            <>
              {messages.map((message, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: message.name === user.firstname ? "flex-start" : "flex-end",
                    marginBottom: "20px"
                  }}
                >
                  <div style={{ maxWidth: "70%" }}>
                    <div style={{ paddingBottom: 10 }}>
                      {message.name}, &nbsp;
                      <small>{formatRelativeTime(message.time)}</small> <br />
                    </div>
                  
                    <div style={{ display: "flex" }}>
                      <div>
                        {message.name === user.firstname && (
                          <>
                            <UserAvatarProjects
                              worker={{
                                firstname: message.name,
                                staff_photo_uri: message.staff_photo_uri,
                              }}
                              size="40"
                              round={true}
                              style={{ marginRight: 10 }}
                            />
                          </>
                        )}
                      </div>
                      <div>
                        {message.name !== user.firstname && (
                          <>
                            <UserAvatarProjects
                              worker={{
                                firstname: message.name,
                                staff_photo_uri: message.staff_photo_uri,
                              }}
                              size={40}
                              style={{ marginRight: 10 }}
                            />
                          </>
                        )}
                      </div>
                      <div
                        style={{
                          padding: "10px 15px",
                          borderRadius: "10px",
                          backgroundColor: message.name === user.firstname ? "#044f66" : "#e9ecef",
                          color: message.name === user.firstname ? "#fff" : "#000"
                        }}
                        dangerouslySetInnerHTML={{ __html: message.text }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}


        </div>
        <div className="messenger-input">
          <input
            type="text"
            placeholder="Type a message..."
            value={newMessage}
            onChange={handleInputChange}
            style={{ backgroundColor: "#f2f2f2" }}
          />
          <button
            style={{ backgroundColor: "#f2f2f2", color: "gray" }}
            onClick={openAddModal}
          >
            <Icon name="attach"></Icon>
            <Modal open={AddmodalIsOpen} onClose={closeModal} size="small">
              <Modal.Header>Upload Attachment(s)</Modal.Header>
              <Form loading={loading}>
                <Modal.Content>
                  <Dropzone
                    onChange={updateFiles}
                    value={files}
                    maxFileSize={8 * 1024 * 1024}
                  >
                    {files.map((file) => (
                      <FileMosaic
                        key={file.id}
                        {...file}
                        onDelete={removeFile}
                        info
                        preview={true}
                        progress={true}
                      />
                    ))}
                  </Dropzone>
                </Modal.Content>
              </Form>

              <Modal.Actions>


                {loading === false && (
                  <>
                    <Button onClick={handleUpload} color="primary">
                      Upload
                    </Button>
                  </>
                )}
              </Modal.Actions>
            </Modal>
          </button>{" "}
          &nbsp;
          <button
            style={{ backgroundColor: "#f2f2f2", color: "gray" }}
            onClick={handleSendMessage}
          >
            <Icon name="send"></Icon>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatWidgetMessenger;
