import {
  Breadcrumb,
  Button,
  Form,
  Grid,
  Checkbox,
  Divider,
} from "semantic-ui-react";
import React, { useState } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Link } from "react-router-dom";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { uploadFiles } from "../apiCalls";
import sendApiRequest, { API_URL } from "../api";
import { toast } from "react-toastify";

function CreateGrpReport() {
  // const [collectionDate, setCollectionDate] = useState(new Date());
  const [files, setFiles] = React.useState([]);
  const [pressureCertfiles, setPressureCert] = useState([]);
  const [housingPhotosFiles, setHousingPhotos] = useState([]);

  const updatePressureCertFiles = (incomingFiles) => {
    //do something with the files
    setPressureCert(incomingFiles);
    //even your own upload implementation
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Optional: Add smooth scrolling behavior
    });
  };

  const updateHousingPhotosFiles = (file) => {
    setHousingPhotos(file);
  };

  const removePressureCertFile = (id) => {
    updatePressureCertFiles(pressureCertfiles.filter((x) => x.id !== id));
  };

  const removeHousingPhotosFile = (id) => {
    updateHousingPhotosFiles(housingPhotosFiles.filter((x) => x.id !== id));
  };

  const handleChange = (e) => {
    const {name, value, type, checked} = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const materials_grp = [
    {
        "CODE": "00337161",
        "DESCRIPTION": "22X0.9X3M CU TUBE (TX) EN1057 R250 FORMERLY BS2871 TX",
        "QUANTITY": 7,
        "DISCOUNT": "545.31/100M",
        "AMOUNT": 114.52
    },
    {
        "CODE": "00337172",
        "DESCRIPTION": "28X0.9X3M CU TUBE (TX) EN1057 R250 FORMERLY BS2871 TX\n",
        "QUANTITY": 1,
        "DISCOUNT": "706.98/100M",
        "AMOUNT": 21.21
    },
    {
        "CODE": "18800184",
        "DESCRIPTION": "BOSS 41X41X2.5 PG SLOT 3M BS69461988",
        "QUANTITY": 5,
        "DISCOUNT": 216.48,
        "AMOUNT": 148.29
    },
    {
        "CODE": "00911329",
        "DESCRIPTION": "28MM S12 ELBOW XPRESS COPPER 38300",
        "QUANTITY": 4,
        "DISCOUNT": 12.51,
        "AMOUNT": 15.01
    },
    {
        "CODE": "00911318",
        "DESCRIPTION": "22MM S12 ELBOW XPRESS COPPER 38290",
        "QUANTITY": 23,
        "DISCOUNT": 5.83,
        "AMOUNT": 40.23
    },
    {
        "CODE": "00911661",
        "DESCRIPTION": "15MM S12S STREET ELBOW XPRESS COPPER 38318",
        "QUANTITY": 1,
        "DISCOUNT": 3.54,
        "AMOUNT": 1.06
    },
    {
        "CODE": "00911672",
        "DESCRIPTION": "22MM S12S STREET ELBOW XPRESS COPPER 38320",
        "QUANTITY": 10,
        "DISCOUNT": 6.15,
        "AMOUNT": 18.45
    },
    {
        "CODE": "00912534",
        "DESCRIPTION": "28MM S21S OBTUSE STREET ELBOW XPRESS COPPER 38406",
        "QUANTITY": 1,
        "DISCOUNT": 19.71,
        "AMOUNT": 5.91
    },
    {
        "CODE": "00912523",
        "DESCRIPTION": "22MM S21S OBTUSE STREET ELBOW XPRESS COPPER 38405",
        "QUANTITY": 2,
        "DISCOUNT": 5.68,
        "AMOUNT": 3.41
    },
    {
        "CODE": "00912460",
        "DESCRIPTION": "22MM S21 OBTUSE ELBOW XPRESS COPPER 38412",
        "QUANTITY": 1,
        "DISCOUNT": 6.29,
        "AMOUNT": 1.89
    },
    {
        "CODE": "00913964",
        "DESCRIPTION": "22X22X.5 S30 FEMALE BRANCH TEE XPRESS COPPER 38591",
        "QUANTITY": 9,
        "DISCOUNT": 29.19,
        "AMOUNT": 78.81
    },
    {
        "CODE": "00913333",
        "DESCRIPTION": "22X15X22MM S26 END REDUCED TEE XPRESS COPPER 38510",
        "QUANTITY": 3,
        "DISCOUNT": 17.71,
        "AMOUNT": 15.94
    },
    {
        "CODE": "00912888",
        "DESCRIPTION": "22MM S24 EQUAL TEE XPRESS COPPER 38460",
        "QUANTITY": 5,
        "DISCOUNT": 10.02,
        "AMOUNT": 15.03
    },
    {
        "CODE": "00910733",
        "DESCRIPTION": "28MM S1 STRAIGHT COUPLING XPRESS COPPER 38030",
        "QUANTITY": 1,
        "DISCOUNT": 11.07,
        "AMOUNT": 3.32
    },
    {
        "CODE": "00911222",
        "DESCRIPTION": "28X22MM S6 REDUCER XPRESS COPPER 38204",
        "QUANTITY": 2,
        "DISCOUNT": 10.9,
        "AMOUNT": 6.54
    },
    {
        "CODE": "00911200",
        "DESCRIPTION": "22X15MM S6 REDUCER XPRESS COPPER 38200",
        "QUANTITY": 1,
        "DISCOUNT": 3.81,
        "AMOUNT": 1.14
    },
    {
        "CODE": "62240688",
        "DESCRIPTION": "22X1 BSPP MALE IRON COUPLER DZR BOSS COMPRESSION FITTINGS",
        "QUANTITY": 8,
        "DISCOUNT": "1196.71/100",
        "AMOUNT": 14.36
    },
    {
        "CODE": "62240910",
        "DESCRIPTION": "22X.75 BSPP STR FI COUPLING DZR BOSS COMPRESSION FITTINGS",
        "QUANTITY": 2,
        "DISCOUNT": "1007.16/100",
        "AMOUNT": 3.02
    },
    {
        "CODE": "00911019",
        "DESCRIPTION": "22X.75 S3 MALE COUPLING XPRESS COPPER 38116",
        "QUANTITY": 2,
        "DISCOUNT": 14.11,
        "AMOUNT": 8.47
    },
    {
        "CODE": "62240677",
        "DESCRIPTION": "22X.75 BSPP MALE IRON COUPLER DZR BOSS COMPRESSION FITTINGS",
        "QUANTITY": 1,
        "DISCOUNT": "1012.30/100",
        "AMOUNT": 1.52
    },
    {
        "CODE": "62240921",
        "DESCRIPTION": "22X1 BSPP STRAIGHT FI COUPLING DZR BOSS COMPRESSION FITTINGS",
        "QUANTITY": 2,
        "DISCOUNT": "1443.75/100",
        "AMOUNT": 4.33
    },
    {
        "CODE": "24014527",
        "DESCRIPTION": "25MM 495S BRASS PLUG TAPERED",
        "QUANTITY": 2,
        "DISCOUNT": 21.34,
        "AMOUNT": 6.83
    },
    {
        "CODE": "24012150",
        "DESCRIPTION": "20MM 486S BRASS SPACE NIPPLE",
        "QUANTITY": 3,
        "DISCOUNT": 8.1,
        "AMOUNT": 3.89
    },
    {
        "CODE": "28430712",
        "DESCRIPTION": "20MM 310 THREADED BRASS SOCKET",
        "QUANTITY": 2,
        "DISCOUNT": 4.7,
        "AMOUNT": 3.76
    },
    {
        "CODE": "10460121",
        "DESCRIPTION": "22MM BOSS 46CW Y STRAINER DZR PN16 COMPR 20 MESH WRAS",
        "QUANTITY": 1,
        "DISCOUNT": 8.18,
        "AMOUNT": 6.54
    },
    {
        "CODE": "24036170",
        "DESCRIPTION": "40X15MM 492S GUNMETAL BUSH",
        "QUANTITY": 2,
        "DISCOUNT": 64.58,
        "AMOUNT": 16.79
    },
    {
        "CODE": "28422732",
        "DESCRIPTION": "1/2 250S TYPE A DRAIN OFF COCK WRAS",
        "QUANTITY": 2,
        "DISCOUNT": 4.9,
        "AMOUNT": 3.92
    },
    {
        "CODE": "27200107",
        "DESCRIPTION": "15MM BOSS 372 DRAIN OFF COCK DZR TYPE A LONG TAIL WRAS",
        "QUANTITY": 2,
        "DISCOUNT": 13.15,
        "AMOUNT": 9.21
    },
    {
        "CODE": "62241421",
        "DESCRIPTION": "22X.75 BSPP FI W/PLATE ELBOW DZR BOSS COMPRESSION FITTINGS",
        "QUANTITY": 1,
        "DISCOUNT": "4767.59/100",
        "AMOUNT": 7.15
    },
    {
        "CODE": "37184953",
        "DESCRIPTION": "22MM 968SRL BOSS NP BALL VALVE BRASS F/B CXC WRAS",
        "QUANTITY": 1,
        "DISCOUNT": 55.19,
        "AMOUNT": 8.28
    },
    {
        "CODE": "37184846",
        "DESCRIPTION": "22MM 968SBL BOSS NP BALL VALVE BRASS F/B CXC WRAS",
        "QUANTITY": 1,
        "DISCOUNT": 55.31,
        "AMOUNT": 8.3
    },
    {
        "CODE": "13475362",
        "DESCRIPTION": "M10 515MG/220RMG GALV BACKPLATE TO SUIT 529MG/222RMG",
        "QUANTITY": 10,
        "DISCOUNT": "454.06/100",
        "AMOUNT": 18.16
    },
    {
        "CODE": "19024075",
        "DESCRIPTION": "M10 X 80MM PRE-CUT THREADED ROD 6323008",
        "QUANTITY": 4,
        "DISCOUNT": "208.89/100",
        "AMOUNT": 1.67
    },
    {
        "CODE": "19024097",
        "DESCRIPTION": "M10 X 100MM PRE-CUT THREADED ROD 6323010",
        "QUANTITY": 4,
        "DISCOUNT": "214.18/100",
        "AMOUNT": 1.71
    },
    {
        "CODE": "18803146",
        "DESCRIPTION": "BOSS CS1 600 CANTILEVER ARM 2 HOLE BASE 41X41X600LONG",
        "QUANTITY": 2,
        "DISCOUNT": "696.27/100",
        "AMOUNT": 13.93
    },
    {
        "CODE": "07622669",
        "DESCRIPTION": "M6X25 PENNY/REPAIR WASHER BZP",
        "QUANTITY": 30,
        "DISCOUNT": "111.26/100",
        "AMOUNT": 0.67
    },
    {
        "CODE": "07622839",
        "DESCRIPTION": "M10X25 PENNY/REPAIR WASHER BZP",
        "QUANTITY": 8,
        "DISCOUNT": "160.90/100",
        "AMOUNT": 0.26
    },
    {
        "CODE": "79040227",
        "DESCRIPTION": "RKW1/2ST-22CU FLAMCO PLATE WHITE FCRKW22 12101",
        "QUANTITY": 2,
        "DISCOUNT": "68.71/100",
        "AMOUNT": 0.55
    },
    {
        "CODE": "18802602",
        "DESCRIPTION": "BOSS M10 SQUARE PLATE 41X41/21 FP31/10",
        "QUANTITY": 34,
        "DISCOUNT": "453.29/100",
        "AMOUNT": 15.41
    },
    {
        "CODE": "19300011",
        "DESCRIPTION": "15MM BOSS 2PC LINED CLIP 13/20MM M8/M10 RLXL015 BSI",
        "QUANTITY": 1,
        "DISCOUNT": 1.56,
        "AMOUNT": 0.7
    },
    {
        "CODE": "19300033",
        "DESCRIPTION": "22MM BOSS 2PC LINED CLIP 21/26MM M8/M10 RLXL022 BSI",
        "QUANTITY": 30,
        "DISCOUNT": 1.78,
        "AMOUNT": 24.03
    },
    {
        "CODE": "19300044",
        "DESCRIPTION": "28MM BOSS 2PC LINED CLIP 26/30MM M8/M10 RLXL028 BSI",
        "QUANTITY": 2,
        "DISCOUNT": 1.78,
        "AMOUNT": 1.6
    },
    {
        "CODE": "07622455",
        "DESCRIPTION": "M10 HEX FULL NUT BZP GRADE 8.8",
        "QUANTITY": 100,
        "DISCOUNT": "155.56/100",
        "AMOUNT": 3.11
    },
    {
        "CODE": "19024042",
        "DESCRIPTION": "M10 X 50MM PRE-CUT THREADED ROD 6323005",
        "QUANTITY": 40,
        "DISCOUNT": "82.95/100",
        "AMOUNT": 6.64
    },
    {
        "CODE": "18801323",
        "DESCRIPTION": "BOSS M10 PLAIN CHANNEL NUTS TO SUIT 41X41/21 RAIL M10 PZP",
        "QUANTITY": 34,
        "DISCOUNT": "346.57/100",
        "AMOUNT": 11.78
    },
    {
        "CODE": "00913975",
        "DESCRIPTION": "22X22X.75 S30 FEMALE TEE XPRESS COPPER",
        "QUANTITY": 2,
        "DISCOUNT": 48.38,
        "AMOUNT": 29.03
    },
    {
        "CODE": "18802720",
        "DESCRIPTION": "BOSS AB01 ANGLE BRACKET 2 HOLE 41X41/21",
        "QUANTITY": 4,
        "DISCOUNT": "1321.75/100",
        "AMOUNT": 5.29
    }
  ];

  const [formData, setFormData] = useState({
    destination: "",
    orderNo: "",
    arrivingDate: "",
    collectionDate: "",
    serialNoType: "",
    astiTwoTenSerialNo: "",
    astiTwoTenSerialNoTwo: "",
    gleSerialNo: "",
    glcSerialNo: "",
    NineKWImmersion: "",
    SixKWImmersion: "",
    bufferSerialNo: "",
    airSourceSerialNo: "",
    electricBoiler: "",
    pressureTestCert: [],
    housingPhotos: [],
    advecoOrderNo: "",
    domesticHotWaterSystem: "",
    ashpPrimarySystem: "",
    glcTankDataPlate: "",
    glcTankDataPlateSerialNo: "",
    gleTankDataPlate: "",
    gleTankDataPlateSerialNo: "",
    fsOneThousandDataPlate: "Pass",
    fsOneThousandDataPlateSerialNo: "",
    mechanicalInstallCarriedBy: "",
    mechanicalInstallCarriedByDate: "",
    electricalInformationInstallCarriedBy: "",
    electricalInformationInstallDate: "",
    blackGSTUnitInsideControlPanel: "",
    controlPanelModelInsideDoor: "",
    controlPanelModelInsideDoorModel: "",
    controlPanelModelInsideDoorSerialNo: "",
    controlPanelModelInsideDoorIMEI: "",
    blackGSTUnitInsideControlPanelIMEI: "",
    blackGSTUnitInsideControlPanelModel: "",
    blackGSTUnitInsideControlPanelSerialNo: "",
    advecoContactDetailsStickerInsideDoor: "",
    immersionHeaterBrandingLabels: "",
    immersionHeaterBrandingLabelsModel: "",
    immersionHeaterBrandingLabelsSerialNo: "",
    advecoBrandStickerOnFPIHeatPump: "",
    checkSafetyValvesArePipedtoDrain: null,
    checkCylindersAreFixedDownCorrectly: null,
    checkAllPipeworkIsClippedAndSecure: null,
    checkAllpipeworkIsInsulatedAndJointsTaped: null,
    checkHousingForAnyDamageExternallyAndInternally: null,
    checkFrostProtectionCableWrappedAroundFlowAndReturn: null,
    checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise: null,
    checkPipeworkExitingTheUnitsIsNotTooLong: null,
    qualityChecksOfElectricialInstallationCarriedBy: "",
    qualityChecksOfElectricialInstallationCarriedByDate: "",
    qualityChecksOfElectricialInstallationCarriedByTwo: "",
    qualityChecksOfElectricialInstallationCarriedByTwoDate: "",
    qualityChecksOfMechnicalInstallationCarriedBy: "",
    qualityChecksOfMechnicalInstallationCarriedByDate: "",
    qualityChecksOfMechnicalInstallationCarriedByTwo: "",
    qualityChecksOfMechnicalInstallationCarriedByDateTwo: "",
    checkMechanicalWorksComplete: "",
    checkElectricalWorksComplete: "",
    controlPanelSecurelyMounted: "",
    controlPanelTerminatedAndLabelled: "",
    tubularHeaterFuseSpurInstalledAndConnected: "",
    lightAndSwitchInstalledAndConnected: "",
    dutyImmersionIsolatorAndConnection: "",
    dutyStatConnectedRearOfTankAndSet: "",
    standbyImmersionIsolatorAndConnection: "",
    airSourceHeatPumpIsolatorAndConnection: "",
    destratPumpSpurAndConnection: "",
    ensurePipeworkPressureTested: "",
    kioskLight: "",
    returnPump: "",
    rcdSocketInstalled: "",
    tubularHeater: "",
    earthBounding: "",
    nicei: "",
    ensureAllIsolators: "",
    checkUnitIsCleanInside: "",
    checkNoScrewsSticking: "",
    gsmToPanel: "",
    controlPanelWiring: "",
    allManualsArePresent: "",
    grpNote: "",
  });

  const handlesaveReport = async () => {
    const data = {
      destination: formData.destination,
      orderNo: formData.orderNo,
      arrivingDate: formData.arrivingDate,
      grpNote: formData.grpNote,
      meta: {
        collectionDate: formData.collectionDate,
        serialNoType: formData.serialNoType,
        astiTwoTenSerialNo: formData.astiTwoTenSerialNo,
        astiTwoTenSerialNoTwo: formData.astiTwoTenSerialNoTwo,
        gleSerialNo: formData.gleSerialNo,
        glcSerialNo: formData.glcSerialNo,
        NineKWImmersion: formData.NineKWImmersion,
        SixKWImmersion: formData.SixKWImmersion,
        bufferSerialNo: formData.bufferSerialNo,
        airSourceSerialNo: formData.airSourceSerialNo,
        electricBoiler: formData.electricBoiler,
        electricianCert: [],
        pressureTestCerts: [],
        photos: [],
        controlQualityCheck: {
          advecoNo: formData.advecoOrderNo,
          mechanicalInfo: {
            checkMechanicalWorksComplete: formData.checkMechanicalWorksComplete,
            installationCarriedOutBy: formData.mechanicalInstallCarriedBy,
            installationCarriedOutDate: formData.mechanicalInstallCarriedByDate,
            pressureTestReport: {
              domesticHotWaterSystem: formData.domesticHotWaterSystem,
              ashpPrimarySystem: formData.ashpPrimarySystem,
            },
            confirmAllUnitsAreCorrectlyLabeled: {
              glcTankDataPlate: {
                report: formData.glcTankDataPlate,
                serialNo: formData.glcTankDataPlateSerialNo,
              },
              gleTankDataPlate: {
                report: formData.gleTankDataPlate,
                serialNo: formData.gleTankDataPlateSerialNo,
              },
              fs1000TankDataPlate: {
                report: formData.fsOneThousandDataPlate,
                serialNo: formData.fsOneThousandDataPlateSerialNo,
              },
            },
          },
          electricalInfo: {
            checkElectricalWorksComplete: formData.checkElectricalWorksComplete,
            electricalInformationInstallCarriedBy: formData.electricalInformationInstallCarriedBy,
            electricalInformationInstallDate: formData.electricalInformationInstallDate,
            confirmAllUnitsAreCorrectlyLabeled: {
              controlPanelModelInsideDoor: {
                report: formData.controlPanelModelInsideDoor,
                serialNo: formData.controlPanelModelInsideDoorSerialNo,
                imei: formData.controlPanelModelInsideDoorIMEI,
              },
              blackGstUnitInsideControlPanel: {
                report: formData.blackGSTUnitInsideControlPanel,
                imei: formData.blackGSTUnitInsideControlPanelIMEI,
                model: formData.blackGSTUnitInsideControlPanelModel
              },
              immersionHeaterBrandingLabels: {
                report: formData.immersionHeaterBrandingLabels,
                serialNo: formData.immersionHeaterBrandingLabelsSerialNo,
                model: formData.immersionHeaterBrandingLabelsModel,
              },
              advecoContactDetailsStickerInsideDoor: {
                report: formData.advecoContactDetailsStickerInsideDoor,
              },
              advecoBrandStickerOnFpiHeatPump: {
                report: formData.advecoBrandStickerOnFPIHeatPump,
              },
            },
          },
          qualityChecksForMechanicalInstall: [
            {
              installationCarriedOutBy: formData.mechanicalInstallCarriedBy,
              installationCarriedOutDate:
                formData.mechanicalInstallCarriedByDate,
            },
            {
              installationCarriedOutBy:
                formData.qualityChecksOfMechnicalInstallationCarriedByTwo,
              installationCarriedOutDate:
                formData.qualityChecksOfMechnicalInstallationCarriedByDateTwo,
            },
          ],
          qualityChecksList: {
            checkHousingForAnyDamageExternallyAndInternally:
              formData.checkHousingForAnyDamageExternallyAndInternally,
            checkAllpipeworkIsInsulatedAndJointsTaped: formData.checkAllpipeworkIsInsulatedAndJointsTaped,
            checkUnitIsCleanInside: formData.checkUnitIsCleanInside,
            checkFrostProtectionCableWrappedAroundFlowAndReturn: formData.checkFrostProtectionCableWrappedAroundFlowAndReturn,
            checkCylindersAreFixedDownCorrectly: formData.checkCylindersAreFixedDownCorrectly,
            checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise: formData.checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise,
            checkAllPipeworkIsClippedAndSecure: formData.checkAllPipeworkIsClippedAndSecure,
            ensurePipeworkPressureTested: formData.ensurePipeworkPressureTested,
            checkPipeworkExitingTheUnitsIsNotTooLong: formData.checkPipeworkExitingTheUnitsIsNotTooLong,
            checkSafetyValvesArePipedtoDrain: formData.checkSafetyValvesArePipedtoDrain
          },
          qualityCheckOfElectricalInstallation: {
            qualityChecksOfElectricalInstallationCarriedBy: formData.qualityChecksOfElectricialInstallationCarriedBy,
            qualityChecksOfElectricalInstallationCarriedByDate: formData.qualityChecksOfElectricialInstallationCarriedByDate,
            qualityChecksOfElectricalInstallationCarriedByTwo: formData.qualityChecksOfElectricialInstallationCarriedByTwo,
            qualityChecksOfElectricialInstallationCarriedByTwoDate: formData.qualityChecksOfElectricialInstallationCarriedByTwoDate,
            qualityChecksList: {
              controlPanelSecurelyMounted: formData.controlPanelSecurelyMounted,
              controlPanelTerminatedAndLabelled: formData.controlPanelTerminatedAndLabelled,
              tubularHeaterFuseSpurInstalledAndConnected: formData.tubularHeaterFuseSpurInstalledAndConnected,
              lightAndSwitchInstalledAndConnected: formData.lightAndSwitchInstalledAndConnected,
              dutyImmersionIsolatorAndConnection: formData.dutyImmersionIsolatorAndConnection,
              dutyStatConnectedRearOfTankAndSet: formData.dutyStatConnectedRearOfTankAndSet,
              standbyImmersionIsolatorAndConnection: formData.standbyImmersionIsolatorAndConnection,
              airSourceHeatPumpIsolatorAndConnection: formData.airSourceHeatPumpIsolatorAndConnection,
              destrat: formData.destratPumpSpurAndConnection,
              kioskLight: formData.kioskLight,
              returnPump: formData.returnPump,
              rcdSocketInstalled: formData.rcdSocketInstalled,
              tubularHeater: formData.tubularHeater,
              earthBounding: formData.earthBounding,
              NICEIC: formData.nicei,
              ensureAllIsolators: formData.ensureAllIsolators,
              checkUnitIsCleanInside: formData.checkUnitIsCleanInside,
              checkNoScrewsSticking: formData.checkNoScrewsSticking,
              gsmToPanel: formData.gsmToPanel,
              controlPanelWiring: formData.controlPanelWiring,
              allManualsArePresent: formData.allManualsArePresent
            }
          }
        },
      },
    };

    // Construct formData here, including the photoUri if it was set
    const apiData = {
      ...data,
    };

    if (pressureCertfiles.length > 0) {
      // Upload files and set the photoUri
      const uploadData = await uploadFiles(pressureCertfiles);
      const pressureTestCerts = uploadData.data.map((x) => {
        return {
          name: x.file_name,
          link: x.urls.path,
        };
      });
      apiData.meta.pressureTestCerts = pressureTestCerts;
    }

    if (housingPhotosFiles.length > 0) {
      const uploadData = await uploadFiles(housingPhotosFiles);
      const photos = uploadData.data.map((x) => {
        return {
          name: x.file_name || "",
          link: x.urls.path,
        };
      });
      apiData.meta.photos = photos;
    }
    
    // // Update the first item quantity
    // rh[0].QTY = quantity;

    // // Update the quantities for all the materials
    // for (let i = 1; i < rh.length; i++) {
    //     rh[i].QTY *= apiData.pipekitQty;
    // }

    apiData.materials = JSON.stringify(materials_grp);

    console.log(apiData);

    const r = await sendApiRequest("post", "/grp/create", apiData);

    console.log(r)

    toast("GRP Record Created");

    scrollToTop();
  };

  return (
    <AdminDashLayout>
      <>
        <div style={{ paddingTop: 40, paddingRight: 10 }}>
          <h3 className={"primary-color"}>
            GRP HOUSING, DELIVERY & COLLECTION SYSTEM
          </h3>
          <Breadcrumb>
            <Link to={"/dashboard"}>
              <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right chevron" />
            <Link to={"/dashboard/grp"}>
              <Breadcrumb.Section link>GRP System</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right arrow" />
            <Breadcrumb.Section>Add A New Record</Breadcrumb.Section>
          </Breadcrumb>
        </div>
        <div style={{ paddingTop: 30, paddingRight: 20 }}>
          <Grid>
            <Grid.Column width={10}>
              <Form>
                <Grid>
                  <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="Destination"
                      placeholder="Enter Destination"
                      required={"true"}
                      value={formData.destination}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "destination",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="Order No"
                      placeholder="Enter Order No"
                      value={formData.orderNo}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "orderNo",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <Form.Input
                      type="date"
                      size="medium"
                      label="GRP HOUSING ARRIVING DATE"
                      placeholder="Enter GRP HOUSING ARRIVING DATE"
                      value={formData.arrivingDate}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "arrivingDate",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <Form.Input
                      size="medium"
                      type="date"
                      label="COLLECTION DATE (LEAVING US)"
                      placeholder="Enter COLLECTION DATE (LEAVING US)"
                      value={formData.collectionDate}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "collectionDate",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>

                  <Grid.Column width={10}>
                      <Form.Field>
                        <Checkbox
                          label="ASTI 210"
                          checked={
                            formData.serialNoType === "ASTI"
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "serialNoType",
                                  value: "ASTI",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          label="GLC 200"
                          checked={
                            formData.serialNoType === "GLC"
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "serialNoType",
                                  value: "GLC",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  
                  {formData.serialNoType === 'GLC' && (
                    <>
                    <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="GLE Serial No"
                      placeholder="Enter GLE Serial No"
                      value={formData.gleSerialNo}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "gleSerialNo",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="GLC Serial No"
                      placeholder="Enter GLC Serial No"
                      value={formData.glcSerialNo}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "glcSerialNo",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                    </>
                  )}

{formData.serialNoType === 'ASTI' && (
                    <>
                    <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="ASTI 210 Serial No"
                      placeholder="Enter ASTI 210 Serial No"
                      value={formData.astiTwoTenSerialNo}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "astiTwoTenSerialNo",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="ASTI 210 Serial No"
                      placeholder="Enter ASTI 210 Serial No"
                      value={formData.astiTwoTenSerialNoTwo}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "astiTwoTenSerialNoTwo",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                    </>
                  )}

                  <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="9KW IMMERSION"
                      placeholder="Enter 9KW IMMERSION"
                      value={formData.NineKWImmersion}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "NineKWImmersion",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="6KW IMMERSION"
                      placeholder="Enter 6KW IMMERSION"
                      value={formData.SixKWImmersion}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "SixKWImmersion",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="BUFFER SERIAL NO"
                      placeholder="Enter BUFFER SERIAL NO"
                      value={formData.bufferSerialNo}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "bufferSerialNo",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="AIR SOURCE SERIAL NO"
                      placeholder="Enter AIR SOURCE SERIAL NO"
                      value={formData.airSourceSerialNo}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "airSourceSerialNo",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <Form.Input
                      size="medium"
                      label="ELECTRIC BOILER"
                      placeholder="Enter ELECTRIC BOILER"
                      value={formData.electricBoiler}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "electricBoiler",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <label style={{ paddingBottom: 20 }}>
                      Pressure Test Certificate Upload
                    </label>
                    <Dropzone
                      onChange={updatePressureCertFiles}
                      value={pressureCertfiles}
                    >
                      {pressureCertfiles.map((file) => (
                        <FileMosaic
                          key={file.id}
                          {...file}
                          onDelete={removePressureCertFile}
                          info
                          preview
                        />
                      ))}
                    </Dropzone>
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <label style={{ paddingBottom: 20 }}>
                      Upload Photos For This Housing
                    </label>
                    <Dropzone
                      accept={"image/*"}
                      onChange={updateHousingPhotosFiles}
                      value={housingPhotosFiles}
                    >
                      {housingPhotosFiles.map((file) => (
                        <FileMosaic
                          key={file.id}
                          {...file}
                          onDelete={removeHousingPhotosFile}
                          info
                          preview
                        />
                      ))}
                    </Dropzone>
                  </Grid.Column>
                </Grid>
                <br />
                <div
                  className={"grp-view-page-further-checks-card"}
                  style={{ borderRadius: "10px", padding: "20px" }}
                >
                  <span className={"primary-color"} style={{ fontSize: 18 }}>
                    GRP HOUSING CONTROL QUALITY CHECKLIST
                  </span>
                  <br />
                  <br />
                  <Grid>
                    <Grid.Column width={16}>
                      <Form.Input
                        size="medium"
                        placeholder="Enter Adveco Order Number"
                        label="Enter Adveco Order Number"
                        value={formData.advecoOrderNo}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "advecoOrderNo",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <br />
                  <span className={"primary-color"} style={{ fontSize: 15 }}>
                    <u>Mechanical Information</u>
                  </span>
                  <br />
                  <br />
                  <Grid>
                    <Grid.Column width={8}>
                      <Form.Input
                        label="Installation Carried Out By"
                        size="medium"
                        placeholder="Installation Carried Out By"
                        value={formData.mechanicalInstallCarriedBy}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "mechanicalInstallCarriedBy",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Input
                        label="Date"
                        type="date"
                        size="medium"
                        placeholder="Installation Carried Out By"
                        value={formData.mechanicalInstallCarriedByDate}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "mechanicalInstallCarriedByDate",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <br />
                  <span className={"primary-color"} style={{ fontSize: 15 }}>
                    <u>Pressure Test Report</u>
                  </span>
                  <br />
                  <br />
                  <Grid>
                    <Grid.Column width={8}>
                      <Form.Field>
                        <label>Domestic Hot Water System</label>
                        <Checkbox
                          name="domesticHotWaterSystem"
                          label="Pass"
                          value={"Pass"}
                          checked={formData.domesticHotWaterSystem === "Pass"}
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "domesticHotWaterSystem",
                                  value: "Pass",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          name="domesticHotWaterSystem"
                          label="Fail"
                          value={"Fail"}
                          checked={formData.domesticHotWaterSystem === "Fail"}
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "domesticHotWaterSystem",
                                  value: "Fail",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Field>
                        <label>ASHP Primary System</label>
                        <Checkbox
                          label="Pass"
                          name="ashpPrimarySystem"
                          checked={formData.ashpPrimarySystem === "Pass"}
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "ashpPrimarySystem",
                                  value: "Pass",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          label="Fail"
                          name="ashpPrimarySystem"
                          checked={formData.ashpPrimarySystem === "Fail"}
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "ashpPrimarySystem",
                                  value: "Fail",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                  <br />
                  <span className={"primary-color"} style={{ fontSize: 15 }}>
                    <u>Confirm All Units Are Correctly Labeled</u>
                  </span>
                  <br />
                  <br />
                  <Grid>
                    <Grid.Column width={5}>
                      <Form.Field>
                        <label>GLC Tank Data Plate</label>
                        <Checkbox
                          label="Pass"
                          name="glcTankDataPlate"
                          checked={formData.glcTankDataPlate === "Pass"}
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "glcTankDataPlate",
                                  value: "Pass",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          label="Fail"
                          checked={formData.glcTankDataPlate === "Fail"}
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "glcTankDataPlate",
                                  value: "Fail",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    
                    <Grid.Column width={5} floated={"left"}>
                      <Form.Input
                        size="small"
                        placeholder="Serial No"
                        style={{ marginTop: "10px" }}
                        value={formData.glcTankDataPlateSerialNo}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "glcTankDataPlateSerialNo",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={5}>
                      <Form.Field>
                        <label>GLE Tank Data Plate</label>
                        <Checkbox
                          label="Pass"
                          name="gleTankDataPlate"
                          checked={formData.gleTankDataPlate == "Pass"}
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "gleTankDataPlate",
                                  value: "Pass",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          label="Fail"
                          checked={formData.gleTankDataPlate == "Fail"}
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "gleTankDataPlate",
                                  value: "Fail",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    
                    <Grid.Column width={5} floated={"left"}>
                      <Form.Input
                        size="small"
                        placeholder="Serial No"
                        style={{ marginTop: "10px" }}
                        value={formData.gleTankDataPlateSerialNo}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "gleTankDataPlateSerialNo",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={5}>
                      <Form.Field>
                        <label>FS1000 Tank Data Plate</label>
                        <Checkbox
                          label="Pass"
                          name="fs1000TankDataPlate"
                          checked={formData.fs1000TankDataPlate === "Pass"}
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "fs1000TankDataPlate",
                                  value: "Pass",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          label="Fail"
                          checked={formData.fs1000TankDataPlate === "Fail"}
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "fs1000TankDataPlate",
                                  value: "Fail",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    
                    <Grid.Column width={5} floated={"left"}>
                      <Form.Input
                        size="small"
                        placeholder="Serial No"
                        style={{ marginTop: "10px" }}
                        value={formData.fsOneThousandDataPlateSerialNo}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "fsOneThousandDataPlateSerialNo",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <Divider />
                  <span className={"primary-color"} style={{ fontSize: 15 }}>
                    <u>Electrical Information</u>
                  </span>
                  <br />
                  <br />
                  <Grid>
                    <Grid.Column width={8}>
                      <Form.Input
                        size="medium"
                        label="Installation Carried Out By"
                        value={formData.electricalInformationInstallCarriedBy}
                        placeholder="Installation Carried Out By"
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "electricalInformationInstallCarriedBy",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Input
                        size="small"
                        type="date"
                        label="Date"
                        placeholder="Date"
                        style={{ marginTop: "" }}
                        value={formData.electricalInformationInstallDate}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "electricalInformationInstallDate",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <br />
                  <span className={"primary-color"} style={{ fontSize: 15 }}>
                    <u>Confirm All Units Are Correctly Labeled</u>
                  </span>
                  <br />
                  <br />
                  <Grid>
                    <Grid.Column width={7}>
                      <Form.Field>
                        <label>Control Panel Model Sticker Inside Door</label>
                        <Checkbox
                          label="Pass"
                          checked={
                            formData.controlPanelModelInsideDoor === "Pass"
                          }
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "controlPanelModelInsideDoor",
                                  value: "Pass",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          label="Fail"
                          checked={
                            formData.controlPanelModelInsideDoor === "Fail"
                          }
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "controlPanelModelInsideDoor",
                                  value: "Fail",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={4} floated={"left"}>
                      <Form.Input
                        size="small"
                        placeholder="Serial No"
                        style={{ marginTop: "10px" }}
                        value={formData.controlPanelModelInsideDoorSerialNo}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "controlPanelModelInsideDoorSerialNo",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={4} floated={"left"}>
                      <Form.Input
                        size="small"
                        placeholder="IMEI"
                        style={{ marginTop: "10px" }}
                        value={formData.controlPanelModelInsideDoorIMEI}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "controlPanelModelInsideDoorIMEI",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={7}>
                      <Form.Field>
                        <label>Black GST Unit Inside Control Panel</label>
                        <Checkbox
                          label="Pass"
                          name="blackGSTUnitInsideControlPanel"
                          checked={
                            formData.blackGSTUnitInsideControlPanel == "Pass"
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "blackGSTUnitInsideControlPanel",
                                  value: "Pass",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          label="Fail"
                          name="blackGSTUnitInsideControlPanel"
                          checked={
                            formData.blackGSTUnitInsideControlPanel == "Fail"
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "blackGSTUnitInsideControlPanel",
                                  value: "Fail",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={4} floated={"left"}>
                      <Form.Input
                        size="small"
                        placeholder="IMEI"
                        style={{ marginTop: "10px" }}
                        value={formData.blackGSTUnitInsideControlPanelIMEI}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "blackGSTUnitInsideControlPanelIMEI",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={4} floated={"left"}>
                      <Form.Input
                        size="small"
                        placeholder="Model"
                        style={{ marginTop: "10px" }}
                        value={formData.blackGSTUnitInsideControlPanelModel}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "blackGSTUnitInsideControlPanelModel",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={7}>
                      <Form.Field>
                        <label>Immersion Heater Branding Labels</label>
                        <Checkbox
                          label="Pass"
                          checked={
                            formData.immersionHeaterBrandingLabels == "Pass"
                          }
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "immersionHeaterBrandingLabels",
                                  value: "Pass",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          label="Fail"
                          checked={
                            formData.immersionHeaterBrandingLabels === "Fail"
                          }
                          onChange={() =>
                            handleChange(
                              {
                                target: {
                                  name: "immersionHeaterBrandingLabels",
                                  value: "Fail",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={4} floated={"left"}>
                      <Form.Input
                        size="small"
                        placeholder="Model"
                        style={{ marginTop: "10px" }}
                        value={formData.immersionHeaterBrandingLabelsModel}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "immersionHeaterBrandingLabelsModel",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={4} floated={"left"}>
                      <Form.Input
                        size="small"
                        placeholder="Serial No"
                        style={{ marginTop: "10px" }}
                        value={formData.immersionHeaterBrandingLabelsSerialNo}
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "immersionHeaterBrandingLabelsSerialNo",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={10}>
                      <Form.Field>
                        <label>
                          Adveco Contact Details Sticker Inside Door
                        </label>
                        <Checkbox
                          label="Pass"
                          checked={
                            formData.advecoContactDetailsStickerInsideDoor ===
                            "Pass"
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "advecoContactDetailsStickerInsideDoor",
                                  value: "Pass",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          label="Fail"
                          checked={
                            formData.advecoContactDetailsStickerInsideDoor ===
                            "Fail"
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "advecoContactDetailsStickerInsideDoor",
                                  value: "Fail",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <Form.Field>
                        <label>Adveco Brand Sticker On FPi Heat Pump</label>
                        <Checkbox
                          label="Pass"
                          checked={
                            formData.advecoBrandStickerOnFPIHeatPump === "Pass"
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "advecoBrandStickerOnFPIHeatPump",
                                  value: "Pass",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          label="Fail"
                          checked={
                            formData.advecoBrandStickerOnFPIHeatPump === "Fail"
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "advecoBrandStickerOnFPIHeatPump",
                                  value: "Fail",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                  <Divider />
                  <br />
                  <span className={"primary-color"} style={{ fontSize: 15 }}>
                    <u>Quality Checks of Mechanical Installation</u>
                  </span>
                  <br />
                  <br />
                  <Grid>
                    <Grid.Column width={8}>
                      <Form.Input
                        size="medium"
                        placeholder="Installation Carried Out By"
                        value={
                          formData.qualityChecksOfMechnicalInstallationCarriedBy
                        }
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "qualityChecksOfMechnicalInstallationCarriedBy",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Input
                        type="date"
                        size="medium"
                        placeholder="Date"
                        value={
                          formData.qualityChecksOfMechnicalInstallationCarriedByDate
                        }
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "qualityChecksOfMechnicalInstallationCarriedByDate",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={8}>
                      <Form.Input
                        size="medium"
                        placeholder="Installation Carried Out By"
                        value={
                          formData.qualityChecksOfMechnicalInstallationCarriedByTwo
                        }
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "qualityChecksOfMechnicalInstallationCarriedByTwo",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Input
                        type="date"
                        size="medium"
                        placeholder="Date"
                        value={
                          formData.qualityChecksOfMechnicalInstallationCarriedByDateTwo
                        }
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "qualityChecksOfMechnicalInstallationCarriedByDateTwo",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <br />
                  <span className={"primary-color"} style={{ fontSize: 15 }}>
                    Quality Checks
                  </span>
                  <br />
                  <small style={{ color: "#d4d4d4" }}>
                    Please tick on completion
                  </small>
                  <br />
                  <br />
                  <Grid>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          Check Housing For Any Damage Externally and Internally
                        </label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.checkHousingForAnyDamageExternallyAndInternally ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkHousingForAnyDamageExternallyAndInternally",
                                  value:
                                    !formData.checkHousingForAnyDamageExternallyAndInternally,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Check Cylinders Are Fixed Down Correctly</label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.checkCylindersAreFixedDownCorrectly ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkCylindersAreFixedDownCorrectly",
                                  value:
                                    !formData.checkCylindersAreFixedDownCorrectly,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          Check that the air source heat pump is secure,
                          including straps around the heat pump and brackets to
                          stabilise the unit during transit
                        </label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise",
                                  value:
                                    !formData.checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Check all pipework is clipped and secure</label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.checkAllPipeworkIsClippedAndSecure === true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkAllPipeworkIsClippedAndSecure",
                                  value:
                                    !formData.checkAllPipeworkIsClippedAndSecure,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Ensure pipework has been pressure tested</label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.ensurePipworkHasBeenPressureTested === true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "ensurePipworkHasBeenPressureTested",
                                  value:
                                    !formData.ensurePipworkHasBeenPressureTested,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          Check all pipework is insulated and joints taped
                        </label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.checkAllpipeworkIsInsulatedAndJointsTaped ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkAllpipeworkIsInsulatedAndJointsTaped",
                                  value:
                                    !formData.checkAllpipeworkIsInsulatedAndJointsTaped,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Check safety valves are piped to drain</label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.checkSafetyValvesArePipedtoDrain === true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkSafetyValvesArePipedtoDrain",
                                  value:
                                    !formData.checkSafetyValvesArePipedtoDrain,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          Check pipework exiting the units is not too long (this
                          may cause damage in transit)
                        </label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.checkPipeworkExitingTheUnitsIsNotTooLong ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkPipeworkExitingTheUnitsIsNotTooLong",
                                  value:
                                    !formData.checkPipeworkExitingTheUnitsIsNotTooLong,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Unit is clean and tidy inside</label>
                        <Checkbox
                          label="Done"
                          checked={formData.checkUnitIsCleanInside === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkUnitIsCleanInside",
                                  value: !formData.checkUnitIsCleanInside,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          Check frost protection cable wrapped around flow and
                          return
                        </label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.checkFrostProtectionCableWrappedAroundFlowAndReturn ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkFrostProtectionCableWrappedAroundFlowAndReturn",
                                  value:
                                    !formData.checkFrostProtectionCableWrappedAroundFlowAndReturn,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label style={{ color: "#800f2f" }}>
                          Mechanical Works Complete?
                          
                        </label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.checkMechanicalWorksComplete ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkMechanicalWorksComplete",
                                  value:
                                    !formData.checkMechanicalWorksComplete,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        <br />
                        <small>By checking this field, you agree that all mechanical works and checks have been completed to a satisfactory level.</small>
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                  <Divider />
                  <br />
                  <span className={"primary-color"} style={{ fontSize: 15 }}>
                    <u>Quality Checks of Electrical Installation</u>
                  </span>
                  <br />
                  <br />
                  <Grid>
                    <Grid.Column width={8}>
                      <Form.Input
                        size="medium"
                        placeholder="Installation Carried Out By"
                        label="Installation Carried Out By"
                        value={
                          formData.qualityChecksOfElectricialInstallationCarriedBy
                        }
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "qualityChecksOfElectricialInstallationCarriedBy",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Input
                        size="medium"
                        label="Date"
                        type="date"
                        placeholder="Date"
                        value={
                          formData.qualityChecksOfElectricialInstallationCarriedByDate
                        }
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "qualityChecksOfElectricialInstallationCarriedByDate",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={8}>
                      <Form.Input
                        size="medium"
                        placeholder="Installation Carried Out By"
                        label="Installation Carried Out By"
                        value={
                          formData.qualityChecksOfElectricialInstallationCarriedByTwo
                        }
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "qualityChecksOfElectricialInstallationCarriedByTwo",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Form.Input
                        size="medium"
                        label="Date"
                        type="date"
                        placeholder="Date"
                        value={
                          formData.qualityChecksOfElectricialInstallationCarriedByTwoDate
                        }
                        onChange={(e) =>
                          handleChange(
                            {
                              target: {
                                name: "qualityChecksOfElectricialInstallationCarriedByTwoDate",
                                value: e.target.value,
                              },
                            },
                            "grp"
                          )
                        }
                      />
                    </Grid.Column>
                  </Grid>
                  <br />
                  <span className={"primary-color"} style={{ fontSize: 15 }}>
                    Quality Checks
                  </span>
                  <br />
                  <small style={{ color: "#d4d4d4" }}>
                    Please tick on completion
                  </small>
                  <br />
                  <br />
                  <Grid>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Control panel securely mounted</label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.controlPanelSecurelyMounted === true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "controlPanelSecurelyMounted",
                                  value: !formData.controlPanelSecurelyMounted,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Control panel terminated and labelled</label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.controlPanelTerminatedAndLabelled === true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "controlPanelTerminatedAndLabelled",
                                  value:
                                    !formData.controlPanelTerminatedAndLabelled,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          Tubular heater, fuse spur installed and connected
                        </label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.tubularHeaterFuseSpurInstalledAndConnected ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "tubularHeaterFuseSpurInstalledAndConnected",
                                  value:
                                    !formData.tubularHeaterFuseSpurInstalledAndConnected,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Light and switch installed and connected</label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.lightAndSwitchInstalledAndConnected ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "lightAndSwitchInstalledAndConnected",
                                  value:
                                    !formData.lightAndSwitchInstalledAndConnected,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Duty immersion isolator and connection</label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.dutyImmersionIsolatorAndConnection === true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "dutyImmersionIsolatorAndConnection",
                                  value:
                                    !formData.dutyImmersionIsolatorAndConnection,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          Duty stat connected rear of tank and set to 630C
                        </label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.dutyStatConnectedRearOfTankAndSet === true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "dutyStatConnectedRearOfTankAndSet",
                                  value:
                                    !formData.dutyStatConnectedRearOfTankAndSet,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Standby immersion isolator and connection</label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.standbyImmersionIsolatorAndConnection ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "standbyImmersionIsolatorAndConnection",
                                  value:
                                    !formData.standbyImmersionIsolatorAndConnection,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          Air source heat pump isolator and connection
                        </label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.airSourceHeatPumpIsolatorAndConnection ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "airSourceHeatPumpIsolatorAndConnection",
                                  value:
                                    !formData.airSourceHeatPumpIsolatorAndConnection,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Destrat pump spur and connection</label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.destratPumpSpurAndConnection === true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "destratPumpSpurAndConnection",
                                  value: !formData.destratPumpSpurAndConnection,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Kiosk light, switch and connection</label>
                        <Checkbox
                          label="Done"
                          checked={formData.kioskLight === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "kioskLight",
                                  value: !formData.kioskLight,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Return pump, fuse spur and isolator</label>
                        <Checkbox
                          label="Done"
                          checked={formData.returnPump === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "returnPump",
                                  value: !formData.returnPump,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>RCD socket installed and connection</label>
                        <Checkbox
                          label="Done"
                          checked={formData.rcdSocketInstalled === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "rcdSocketInstalled",
                                  value: !formData.rcdSocketInstalled,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          Tubular heater, fuse spur and connection, securely
                          mounted with no damage to brackets
                        </label>
                        <Checkbox
                          label="Done"
                          checked={formData.tubularHeater === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "tubularHeater",
                                  value: !formData.tubularHeater,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          Earth bonding to mechanical services and electrical
                          tray
                        </label>
                        <Checkbox
                          label="Done"
                          checked={formData.earthBounding === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "earthBounding",
                                  value: !formData.earthBounding,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          NICEIC Installation certificate with the unit
                        </label>
                        <Checkbox
                          label="Done"
                          checked={formData.nicei === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "nicei",
                                  value: !formData.nicei,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Ensure all isolators are labelled</label>
                        <Checkbox
                          label="Done"
                          checked={formData.ensureAllIsolators === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "ensureAllIsolators",
                                  value: !formData.ensureAllIsolators,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>Check unit is clean inside</label>
                        <Checkbox
                          label="Done"
                          checked={formData.checkUnitIsCleanInside === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkUnitIsCleanInside",
                                  value: !formData.checkUnitIsCleanInside,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          Check no screws sticking through the housing to
                          outside
                        </label>
                        <Checkbox
                          label="Done"
                          checked={formData.checkNoScrewsSticking === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkNoScrewsSticking",
                                  value: !formData.checkNoScrewsSticking,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          GSM to panel connections are correct (Red wire to
                          positive terminal, black wire to negative, yellow wire
                          to third terminal)
                        </label>
                        <Checkbox
                          label="Done"
                          checked={formData.gsmToPanel === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "gsmToPanel",
                                  value: !formData.gsmToPanel,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          Control panel wiring schematic is included inside
                          control panel door
                        </label>
                        <Checkbox
                          label="Done"
                          checked={formData.controlPanelWiring === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "controlPanelWiring",
                                  value: !formData.controlPanelWiring,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label>
                          All manuals are present (ASHP, water heater, preheat,
                          immersion heaters, control panel)
                        </label>
                        <Checkbox
                          label="Done"
                          checked={formData.allManualsArePresent === true}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "allManualsArePresent",
                                  value: !formData.allManualsArePresent,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column width={15}>
                      <Form.Field>
                        <label style={{ color: "#e85d04" }}>
                          Electrical Works Complete?
                          
                        </label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.checkElectricalWorksComplete ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkElectricalWorksComplete",
                                  value:
                                    !formData.checkElectricalWorksComplete,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        <br />
                        <small>By checking this field, you agree that all mechanical works and checks have been completed to a satisfactory level.</small>
                      </Form.Field>
                    </Grid.Column>
                  </Grid>
                </div>

              <br /> <br />
              <Grid.Column width={11}>
                <Form.TextArea
                    size="medium"
                    label="Note to Merchant"
                    placeholder="Enter Note to Merchant"
                    value={formData.grpNote}
                    onChange={(e) =>
                        handleChange(
                            {
                                target: {
                                    name: "grpNote",
                                    value: e.target.value,
                                },
                            },
                            "grp"
                        )
                    }
                />
              </Grid.Column>

              <br /> <br />
              <div style={{ position: 'fixed', right: '20px', bottom: '20px' }}>
                <Button
                  size="midi"
                  color="primary"
                  content="Save Report"
                  icon="plus"
                  labelPosition="right"
                  onClick={handlesaveReport}
                />
              </div>
              </Form>
            </Grid.Column>
          </Grid>
        </div>
      </>
    </AdminDashLayout>
  );
}

export default CreateGrpReport;
