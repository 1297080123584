import React, {useState, useRef, useEffect} from "react";
import Avatar from "react-avatar";
import {Form, Icon} from "semantic-ui-react";
import {formatRelativeTime} from "../utils";
import sendApiRequest from "../api";
import {toast} from "react-toastify";

const ChatWidget = ({id, messages, user, updateMessages}) => {
    const [newMessage, setNewMessage] = useState("");
    const chatRef = useRef(null);

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (newMessage.trim() !== "") {
            // Prepare message data
            const messageData = {
                conversationId: id,
                content: newMessage
            };

            // Send message to API
            const response = await sendApiRequest(
                "post", 
                "/messages/send", 
                messageData, 
                localStorage.getItem('token')
            );

            if (response.errors || response.error) {
                toast('Error sending message');
                return;
            }

            // If successful, create new message object
            const newChatMessage = [{
                text: newMessage,
                sender: {
                    id: user.id,
                    firstname: user.firstname,
                    staff_photo_uri: user.staff_photo_uri,
                },
                time: new Date().toISOString(),
            }];

            // Update local state
            const updatedMessages = messages ? [...messages, ...newChatMessage] : [...newChatMessage];
            updateMessages(updatedMessages);
            setNewMessage("");
        }
    };

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <Form>
            <div className="chat-container">
                <div className="chat-messages" ref={chatRef}>
                    {messages === null ? (
                        <div className="no-messages">No messages yet</div>
                    ) : (
                        messages.map((message, index) => (
                            <div
                                key={index}
                                className={`message ${message.sender.id === user.id ? "sender-message" : "client-message"}`}
                            >
                                <div className="message-content">
                                    <div className="message-header" style={{textAlign: message.sender.id === user.id ? 'right' : 'left', paddingBottom: 5}}>
                                        <small>{formatRelativeTime(message.time)}</small>
                                    </div>
                                    <div className="message-bubble" style={{display: 'flex', justifyContent: message.sender.id === user.id ? 'flex-end' : 'flex-start', alignItems: 'center'}}>
                                        {message.sender.id !== user.id && (
                                            <Avatar
                                                name={message.sender.firstname}
                                                size="40"
                                                round={true}
                                                style={{marginRight: 10}}
                                                src={message.sender.staff_photo_uri}
                                            />
                                        )}
                                        <div className={message.sender.id === user.id ? "message-text-sender" : "message-text-client"}
                                             style={{
                                                 backgroundColor: message.sender.id === user.id ? '#0084ff' : '#e4e6eb',
                                                 color: message.sender.id === user.id ? 'white' : 'black',
                                                 borderRadius: '18px',
                                                 padding: '8px 12px',
                                                 maxWidth: '70%',
                                                 wordWrap: 'break-word'
                                             }}>
                                            {message.text}
                                        </div>
                                        {message.sender.id === user.id && (
                                            <Avatar
                                                name={message.sender.firstname}
                                                size="40"
                                                round={true}
                                                style={{marginLeft: 10}}
                                                src={message.sender.staff_photo_uri}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <div className="message-input">
                    <input
                        type="text"
                        placeholder="Type a message..."
                        value={newMessage}
                        onChange={handleInputChange}
                    />
                    <button onClick={handleSendMessage}>
                        <Icon name="send"/>
                    </button>
                </div>
            </div>
        </Form>
    );
};

export default ChatWidget;
